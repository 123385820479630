<template>
  <div id="VidedList" style="width: 100%; height: 100%">
    <div
      v-for="(value, key) in maxVideoNum"
      :id="'VidedList' + (key + 1)"
      class="videoDiv"
      style="overflow: hidden; float: left"
      :style="{
        width: getSize(key) + '%',
        height: getSize(key) + '%',
      }"
      :key="key"
      @dbclick="cblVideoQPClick(key + 1)"
    >
      <div class="video" :id="'videoP' + (key + 1)">
        <div :id="'video' + (key + 1)" class="videoObj cover"></div>
        <div
          :id="'videoScreen' + (key + 1)"
          :class="{
            selected: selectedId == key + 1,
            videoScreen: !value[key + 1],
          }"
          @click="videoScreenClick"
        >
          <span class="carVideoName"></span>
          <div
            :id="'videoPlayBtn' + (key + 1)"
            class="videoPlayBtn"
            :class="'playImg' + (key + 1)"
            v-if="value[key + 1]"
          ></div>
          <div
            class="videoInfo errInfo"
            v-if="!value.isOpen || !value.isMuted"
          ></div>
          <!-- 实时视频控件 -->
          <div class="videoCtrlBottom" v-show="value[key + 1] && !isHistory">
            <span
              class="progress"
              :style="{
                width: (getVideoWidthHeight(key + 1).width / 3) * 2 + 'px',
              }"
            >
              <!-- 暂停/播放 -->
              <!-- <i
                v-if="value.currentTime > 0"
                :class="[
                  value.isOpen ? 'el-icon-video-pause' : 'el-icon-video-play',
                  maxVideoNum.length >= 6 ? 'pause-small' : '',
                ]"
                @click="pauseVideoClick(key + 1)"
              ></i> -->
              <span
                class="carPlate"
                :class="maxVideoNum.length >= 6 ? 'text-small' : ''"
                :title="value.plate"
                >{{ value.plate }} &nbsp; &nbsp; &nbsp;{{
                  parseInt(value.kBps)
                }}
                kB/s</span
              >
            </span>
            <span
              class="rightctr"
              :style="{ width: getVideoWidthHeight(key + 1).width / 3 + 'px' }"
            >
              <a
                :class="value.isMuted ? 'mutedVideo' : 'isMutedVideo'"
                @click="mutedVideoClick(key + 1)"
              ></a>
              <a class="closecarVideo" @click="flv_destroy(key + 1, true)"></a>
              <a class="captureVideo" @click="flv_screenshot(key + 1)"></a>
              <a class="quanpcarVideo" @click="quanpVideoClick(key + 1)"></a>
            </span>
          </div>
          <!-- 历史视频 -->
          <div
            class="videoControl"
            v-if="
              showVideoControl && value[key + 1] && historyVideo.currentTime > 0
            "
          >
            <el-slider
              class="commonSlider"
              :show-tooltip="false"
              v-model="historyVideo.currentTime"
              :max="formateTimeRound(timeRound, true)"
              @change="changeVideoTime($event, key + 1)"
            ></el-slider>
            <div class="progress">
              <div class="time">
                <i
                  :class="
                    value.isOpen ? 'el-icon-video-pause' : 'el-icon-video-play'
                  "
                  @click="pauseVideoClick(key + 1)"
                ></i>
                <span>{{ formatTimeBySeconds(historyVideo.currentTime) }}</span>
                /
                <span>{{ formateTimeRound(timeRound) }}</span>

                <span style="margin-left: 20px"
                  >{{ parseInt(value.kBps) }} kB/s
                </span>
              </div>
              <div class="rightctr">
                <a
                  :class="value.isMuted ? 'mutedVideo' : 'isMutedVideo'"
                  @click="mutedVideoClick(key + 1)"
                ></a>
                <!-- 录屏 -->
                <a class="recordVideo" @click="jess_record(key + 1)"></a>
                <a class="captureVideo" @click="flv_screenshot(key + 1)"></a>

                <a class="quanpcarVideo" @click="quanpVideoClick(key + 1)"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseUrl from "@/api/static/config";
import fGetWidthHeight from "./getWidthHeight.js";
import { YHTools } from "@/assets/js/Tools.js";
import { formatDate, getCurent } from "@/common/utils/index";
import { mapGetters } from "vuex";

import TripleDES from "@/api/lib/crypto.js";
import heartbeat from "@/api/lib/heartbeat.js";
import {
  saveVideoLog,
} from "@/api/lib/api.js";

export default {
  name: "videoList",
  props: {
    buttonValue: {
      type: Number,
      default: 4
    },
    isHistory: {
      type: Boolean,
      default: false
    },
    showVideoControl: {
      type: Boolean,
      default: false
    },
    //历史视频时长
    timeRound: {
      type: String
    },
  },
  computed: {
    ...mapGetters({
      nowMenuList: "nowMenuList"
    })
  },
  data () {
    return {
      isOpen: true, // 正在播放
      isMuted: true, // 正在静音
      isShow: false, //加载提示
      isFullScreen: true, // 正在全屏
      fullscreen: false, // 全画面全屏
      videoSrc: baseUrl.videoIframeUrl_wsFlv,
      maxVideoNum: [],
      playerMap: new Map(),
      stateMap: new Map(),
      playVideoList: [],
      pauseVideoMap: new Map(),
      selectedId: null, // 点击屏幕被选中
      firstLoadHistory: true,
      historyVideo: {
        drag: false, // 拖拽进度条 true拖拽中
        currentTime: 0, // 当前播放时间
        lastTime: null, // 标记时间戳
        historyTime: 0, // 
        dragTime: 0,//拖动前的时间标记
      },
      timer_time: null, // 播放定时器
      playVideoObj: [], // 存放视频播放地址
      videoPlaySrc: baseUrl.videoIframeUrl_wsFlv,
      playVideTimeObj: new Map(), // 存放视频播放的时间
      timer_heartbeat: null //心跳定时器
    };
  },
  mounted () {
    // this.system = sessionStorage.getItem("system")
    /** 定时关闭视频 */
    if (!this.isHistory) this.checkPlayOnTime();
    /** 监控全屏事件 */
    let self = this;
    window.onresize = function () {
      self.hanleResize();
    };
    /** 播放流 心跳 关闭 20-02-23*/
    // this.setVideoHeartbeat()
  },
  methods: {
    /** 暂停 | 播放 */
    pauseVideoClick (i) {
      if (this.maxVideoNum[i - 1].isOpen) {
        this.pauseVideoMap.set(this.playVideoList[i - 1], new Date());
      } else {
        if (!this.isHistory) this.flv_seekto(i);
      }
      this.flv_pause(i);
    },

    /** 静音| 取消静音 */
    mutedVideoClick (i) {
      this.flv_muted(i);
      this.maxVideoNum[i - 1].isMuted = !this.maxVideoNum[i - 1].isMuted;
    },

    /** 监控全屏事件 */
    hanleResize () {
      if (document.fullscreenElement) {
        // 进入全屏
        this.fullscreen = true;
        this.isFullScreen = false;
      } else {
        // 退出全屏
        this.fullscreen = false;
        this.isFullScreen = true;
      }
    },

    /** 全屏| 退出全屏 */
    quanpVideoClick (i) {
      let el = document.getElementById(`videoP${i}`);

      this.FullScreen(el);
      let player = this.playerMap.get(i);
      // 当container的宽高发生变化的时候，调用resize方法
      player.resize();
    },

    /** 双击单个页面全屏 */
    cblVideoQPClick (i) {
      let el = document.getElementById(`videoP${i}`);
      this.FullScreen(el);
    },

    /** 多页面全屏 */
    holeVideoFull (i) {
      let el = document.getElementById("VidedList");
      this.FullScreen(el);
      //循环-调整视频宽高变化
      this.playerMap.forEach((item, i) => {
        let player = this.playerMap.get(i);
        if (player) {
          player.resize();
        }
      })
    },

    /** 全屏| 退出全屏 */
    FullScreen (el) {
      let isFullscreen =
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      if (!isFullscreen) {
        // 进入全屏,多重短路表达式
        (el.requestFullscreen && el.requestFullscreen()) ||
          (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
          (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
          (el.msRequestFullscreen && el.msRequestFullscreen());
        return true;
      } else {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        return false;
      }
    },
    /** 显示切换，宽高重置 */
    getVideoWidthHeight (key) {
      let maxWidth = document.getElementById("VidedList").offsetWidth;
      let maxHeight = document.getElementById("VidedList").offsetHeight;
      return fGetWidthHeight(maxWidth, maxHeight, this.maxVideoNum.length, key);
    },
    getSize (index) {
      let videoMax = this.maxVideoNum.length;
      let r = null;
      if (videoMax == 1) {
        r = 100;
      } else if (videoMax == 4) {
        r = 100 / 2;
      } else if (videoMax == 9) {
        r = 100 / 3;
      } else if (videoMax == 16) {
        r = 100 / 4;
      } else {
        if (index == 0) {
          r = 200 / 3;
        } else {
          r = 100 / 3;
        }
      }
      return r
    },

    /**
     *  指定界面视频播放(实时视频)
     * */

    //TODO--------
    playVideo (params) {
      console.log("------------------------------------------------******", params);
      this.$nextTick(function () {
        let self = this;
        let num = 0;
        params.forEach(async (item, key) => {
          let deviceUrl = `${item.device}_channel_${item.channel}`;
          const maxLen = self.playVideoList.length;
          // const maxLen = self.maxVideoNum.length;
          // let url = self.videoPlaySrc[item.url];
          let url = self.videoPlaySrc;
          let space = self.playVideoList.findIndex(val => val === "");
          let index = self.playVideoList.findIndex(
            value => value === deviceUrl
          );
          let last;
          if (index < 0) {
            if (space < 0) {
              self.playVideoList.push(deviceUrl);
              last = maxLen ? maxLen + 1 : 1;
            } else {
              last = space + 1;
              self.playVideoList.splice(space, 1, deviceUrl);
            }
          } else if (index > -1) {
            last = index + 1;
            self.playVideoList.splice(index, 1, deviceUrl);
          }
          // console.log(item.plate + '_' + item.channel, "==========>>>>");
          await self.playload(
            last,
            `${url}${deviceUrl}`,
            `${item.plate}_摄像头${item.channel}`,
            deviceUrl
          );
        });
      });
    },
    /** 点击停止 */
    stopParticularVideo (params) {
      let self = this;
      let num = self.playVideoList.findIndex(item => item === params);
      // self.playVideoList = self.playVideoList.filter(item => item !== params);
      if (num >= 0) {
        self.flv_destroy(num + 1);
      }
    },
    /** 点击屏幕 videoScreen */
    videoScreenClick (i) {
      this.selectedId = i;
    },
    /** 指定界面视频播放(历史视频) */
    playHistoryVideo (params) {
      console.log("历史视频播放----：", params);
      let self = this;
      self.playVideoList = [`${params.device}_channel_${params.channel}`];
      self.playload(
        1,
        `${self.videoSrc}${params.device}_channel_${params.channel}_playback`,
        `${params.plate}_摄像头${params.channel}`,
        `${params.device}_channel_${params.channel}`
      );
      self.firstLoadHistory = false;
      self.historyVideo.drag = false;
    },
    playBackVideoRecover (params) {
      //历史视频只有一个通道，所以i是1
      let player = this.playerMap.get(1);
      let url = `${this.videoSrc}${params.device}_channel_${params.channel}_playback`;
      if (player) {
        this.firstLoadHistory = false;
        // this.historyVideo.drag = false;
        player.playback(url);
        setTimeout(() => {
          player.setPlaybackStartTime(this.historyVideo.dragTime * 1000);
        }, 500)
      }
    },
    /**
     * 显示控制条
     */
    showControlStrip (i) {
      const index = i - 1;
      const cur = this.maxVideoNum[index];
      if (cur) {
        this.maxVideoNum.splice(index, 1, { ...cur, [i]: true });
      }
      // if (this.maxVideoNum[i - 1]) this.$set(this.maxVideoNum[i - 1], i, true);
    },
    /**
     * 隐藏控制条
     */
    hideControlStrip (i) {
      const index = i - 1;
      const cur = this.maxVideoNum[index];
      if (cur) {
        this.maxVideoNum.splice(index, 1, { ...cur, [i]: false });
      }
      // if (this.maxVideoNum[i - 1]) this.$set(this.maxVideoNum[i - 1], i, false);
    },
    playload (i, url, plate, urlKey) {
      console.log("当前播放地址：",i,url);
      let self = this;
      let vid = "video" + i;
      let element = document.getElementById(vid);
      //当前是否存在已播放的实例
      let current = this.maxVideoNum.findIndex(item => item.urlKey === urlKey);
      //存在
      let player = this.playerMap.get(i);
      if (current > -1) {
        console.log("-----正在播放得的player", player);        
        // player.play(url);
        // this.stateMap.set(parseInt(i), true);
        // return;
        return
        player = null;
      }
      const index = i - 1;
      let cur = {
        ...this.maxVideoNum[index],
        isShow: true,
        urlKey,
        isOpen: true
      };
      if (this.maxVideoNum[index] && plate)
        cur = { ...cur, plate: plate.replace("摄像头", "CH") };
      this.maxVideoNum.splice(index, 1, { ...cur });
      // if (typeof player !== "undefined") {
      //   if (player != null) {
      //     console.log("存在有正在播放的视", player);
      //     // player.play(url);
      //     return
      //   }
      // } 
      if (!player) {
        // eslint-disable-next-line no-undef
        element && (player = this.playCreat(element));
      }
      if (player) {
        const currentEle = this.playVideoList[index];
        this.playerMap.set(parseInt(i), player);
        this.stateMap.set(parseInt(i), true);//播放状态
        player.removeWatermark();//关掉性能面板
        //拖动
        player.on('playbackSeek', (data) => {
          console.log("playbackSeek-------ts:", data);// data 为对象          
          // 拿到时间，去调用服务器端的seek接口
          this.$emit("dragChangeVideoTime", data.ts);//拿到数据,下发拖动更新指令
          //更新ui界面时间
          player.setPlaybackStartTime(data.ts);
        })
        //监听网速
        player.on("kBps", (data) => {
          this.$set(
            this.maxVideoNum[index],
            "kBps",
            data
          );
        })
        //播放回调
        player.on("play", (flag) => {
          console.log('play', flag)
          /** 实时播放  暂停后跳转播放 */
          if (!self.isHistory) {
            if (self.pauseVideoMap.get(currentEle)) {
              let setTime = self.pauseVideoMap.get(currentEle);
              let timeDiff = new Date().getTime() - setTime.getTime();
              element.currentTime =
                (element.currentTime + timeDiff / 1000).toFixed(2) - 1;
              self.pauseVideoMap.delete(currentEle);
            }
          }
          self.maxVideoNum[index].isOpen = true;
          this.stateMap.set(parseInt(i), true);
        })
        player.on("pause", (data) => {
          console.log('pause', data)
          if (!self.isHistory) {
            self.pauseVideoMap.set(currentEle, new Date());
          } else {
            this.historyVideo.url = url;
          }
          self.maxVideoNum[index].isOpen = false;
          self.stateMap.set(parseInt(i), false);
        });
        player.on('playFailedAndPaused', err => {
          console.log("player.on('playFailedAndPaused", err);
          if (!this.isHistory) {
            // this.flv_destroy(i);
          } else {
            player.play(url).then(() => {
              if (currentEle) {
                self.playVideTimeObj.set(currentEle, new Date());
              }
              this.$emit('showTips', false);//监听功能状态变化

            }).catch(err => {
              console.log("-------------------*****err", err);
            })
          }
        })
        player.on('timeout', timeout => {
          console.log(timeout);
          this.$emit("issueAndrePlay", urlKey, i);
          //
        });
        player.on("loadingTimeout", error => {
          console.log("//当play()的时候，如果没有数据返回...", error);          
        })
        player.on("playbackTimestamp", time => {
          // console.log("playbackTimestamp", time);
          //每秒触发

          if (this.historyVideo.drag) {
            this.historyVideo.historyTime = time.ts + this.historyVideo.dragTime * 1000;
          } else {
            this.historyVideo.historyTime = time.ts;//暂停播放那里要用到这个播放时间更新进度时间UI---毫秒级别
          }
          this.historyVideoUpdata(i, time.ts);
        })
        //错误监控
        player.on("error",  (error)=>{
          console.log("播放器出错了",error);
          saveVideoLog({log:error}).then(res=>{
            console.log("日志成功");
          })
          this.$emit("issueAndrePlay", urlKey, i);
            player.play(url).then(res=>{
              console.log(res,'播放器出错了-*************************************************************************************************************************');
            });
          // player.play(url).then(r=>{
          //   console.log("重新播放了");
          // });
          if (error === player.ERROR.RangeError) {
            console.log("地址越界了！！！！！！");
            //重新播放或者销毁
            // player.play(url);
          }
        });       
        // 显示控制条
        this.showControlStrip(i);
        setTimeout(() => {
          if (!this.isHistory) {
            player
              .play(url)
              .then(() => {
                if (currentEle) {
                  self.playVideTimeObj.set(currentEle, new Date());
                }
                const cur = { ...this.maxVideoNum[index] };
                this.maxVideoNum.splice(index, 1, { ...cur, isShow: false });
                this.$emit('showTips', false);//监听功能状态变化

              }).catch(err => {
                console.log("-------------------*****err", err);
              })
          } else {
            player.playback(url, {
              fps: 25,//FPS（定频(本地设置)生效）：
              // controlType: 'simple',
              duration: this.formateTimeRound(this.timeRound, true),//js获取当前视频时长              
              showControl: false,//是否显示操作栏(是否显示时间轴)
              isUseFpsRender: true,//FPS设置:动态(根据流)-false,定频（本地设置）-true
              isCacheBeforeDecodeForFpsRender: true,//是否解码前缓冲数据(定频生效)
              isPlaybackPauseClearCache: false,//不断流暂停是否清除缓存数据              
            })
          }
        }, 1000 * 0.3)
      }

    },
    //创建视频实例
    playCreat (container) {
      return new JessibucaPro(
        {
          container: container,
          decoder: '/decoder-pro.js',
          videoBuffer: 0.2 * 10, // 缓存时长
          loadingTimeout: 10 * 1.5,//在刚开始播放的时候，在连接成功之前,如果超过设定时长无数据返回,则回调timeout事件
          isResize: false,
          autowasm: true,//在使用MSE或者 Webcodecs 播放H265的时候，是否自动降级到wasm模式。
          useMSE: true,//是否开启MediaSource硬解码
          useSIMD: true,//是否使用SIMD 软解码，仅仅wasm软解码的时候有效
          useWCS: true,//是否开启Webcodecs硬解码 优先使用useMSE
          usevideoRender: true,//是否使用video标签进行渲染
          wasmUsevideoRender: true,//wasm软解码之后通过video播放流数据，仅仅wasm软解码的时候有效
          heartTimeout: 1 * 10,//单位秒，播放中途,如果超过设定时长无数据返回,则回调timeout事件
          heartTimeoutReplay: true,//是否开启心跳超时之后自动再播放
          heartTimeoutReplayTimes: -1,//heartTimeoutReplay 重试次数
          loadingTimeoutReplay: true,//是否开启loading超时之后自动再播放
          loadingTimeoutReplayTimes: -1,//loadingTimeoutReplay 重试次数,如果想无限次重试，可以设置为-1
          text: "",
          loadingText: "",
          // loadingIcon: false,//是否有loading转圈圈
          // controlAutoHide: true,//鼠标聚焦到播放器内部会显示操作栏
          recordType: "mp4",//视频录制默认的格式，支持webm和mp4。
          debug: false,
          record: true,
          isMulti: true,
          hasAudio: true,
          isNotMute: true,//非静音
          replayUseLastFrameshow: true,//触发重播的时候，是否使用最后一帧来显示
          showBandwidth: true, // 显示网速
          showPerformance: false,//性能面板
          isFlv: true,//是否是flv
          isNakedFlow:true,//裸流格式
          operateBtns: {
            fullscreen: true,
            screenshot: true,
            play: true,
            audio: true,
          },
          // demuxUseWorker: true,//硬解码
          pauseAndNextPlayUseLastFrameShow: true,//调用pause()方法，再次调用play()方法的时候使用最后一帧来显示。
        },

      );
    },
    rePlayVideo (arr, i) {
      let player = this.playerMap.get(i);
      player.play();
    },
    /**
     * 暂停播放----历史视频
     */
    flv_pause (i) {
      let player = this.playerMap.get(i);
      let isPlayingValue = player.isPlaying();//返回是否正在播放中状态。
      if (isPlayingValue) {
        console.log("我点暂停了");
        player.playbackPause();
        // this.$emit("playAndPause",1);//拿到数据,下发拖动更新指令
        this.$set(this.maxVideoNum[i - 1], "kBps", 0);
        this.maxVideoNum[i - 1].isOpen = false;
        this.stateMap.set(parseInt(i), false);
      } else {
        //直接调用，会回到起点播放        
        if (this.historyVideo.historyTime > 2) {
          // this.$emit("playAndPause",0);//拿到数据,下发拖动更新指令
          player.playbackResume();
          this.maxVideoNum[i - 1].isOpen = true;
          setTimeout(() => {
            player.setPlaybackStartTime(this.historyVideo.historyTime);
          }, 300);
        } else {
          setTimeout(() => {
            player.play();
          }, 100)
        }
        this.stateMap.set(parseInt(i), true);
      }
    },

    /**
     * 静音处理
     */
    flv_muted (i) {
      let player = this.playerMap.get(i);
      var result = player.isMute();
      if (result) {
        player.cancelMute();//取消静音
      } else {
        player.mute();
      }
    },
    /** 关闭对应画面 */
    flv_destroy (i, flag) {
      let player = this.playerMap.get(i);
      // console.log("销毁=========》", i, player);
      const index = i - 1;
      const cur = this.playVideoList[index];
      this.playVideoObj.splice(index, 1, "");
      this.playVideoList.splice(index, 1, "");
      const VideoNumIndex = this.maxVideoNum.findIndex(
        item => item.urlKey === cur
      );
      let param = {
        [i]: false,
        currentTime: 0,
        isOpen: false,
        isMuted: true,
        plate: ""
      };
      let vid = "video" + i;
      let element = document.getElementById(vid);
      if (typeof player !== "undefined") {
        player.destroy();
        player = null;
        // 隐藏并显示背景
        this.hideControlStrip(i);
        this.playerMap.delete(i);
        this.stateMap.delete(i);

        if (flag) {
          cur && this.$emit("handleCloseVideo", cur);
        }
      }
      this.maxVideoNum.splice(VideoNumIndex, 1, { ...param });
      this.playVideTimeObj.delete(cur);
    },
    /** 点击关闭全部 */
    flv_destroyAll () {
      let self = this;
      document.querySelectorAll(".videoObj").forEach(item => {
        self.flv_destroy(Number(item.id.replace("video", "")));
      });
      self.playVideoList = [];
      self.playVideoObj = [];
      self.playVideTimeObj.clear();
    },
    /** 跳转播放 */
    flv_seekto (i) {
      let player = this.playerMap.get(i);
      let setTime = this.pauseVideoMap.get(this.playVideoList[i - 1]);
      let timeDiff = new Date().getTime() - setTime.getTime();
      player.currentTime =
        (player.currentTime + timeDiff / 1000).toFixed(2) - 1;
      this.pauseVideoMap.delete(this.playVideoList[i - 1]);
    },
    /**
     * 截屏
     */
    flv_screenshot (i) {
      let player = this.playerMap.get(i);
      const base64 = player.screenshot("test", "png", 1, 'base64');
      if (base64) {
        this.saveFile(base64, "file_" + new Date().getTime() + ".png");
      } else {
        this.$message.warning("很抱歉，无法提取截图文件");
        return
      }
    },
    /**
     * 录制
     */
    jess_record (i) {
      let player = this.playerMap.get(i);
      // 返回是否正在录制。
      let isRecordValue = player.isRecording();
      if (isRecordValue) return;
      let isPlayingValue = player.isPlaying();//返回是否正在播放中状态。
      if (isPlayingValue) {
        player.startRecord(new Date().getTime());
      } else {
        this.$message.warning("视频不是播放状态！");
      }
      /**
       * 暂停录制并下载。
       * stopRecordAndSave(type,fileName)
       * type: 可选，默认download，支持download和blob
       * fileName: 可选，默认时间戳
       */
      //  player.stopRecordAndSave('', 'fileName')
    },
    /**
     * 保存图片至本地
     */
    saveFile (data, filename) {
      // eslint-disable-next-line no-undef
      let saveLink = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      saveLink.href = data;
      saveLink.download = filename;
      let event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      saveLink.dispatchEvent(event);
    },
    isIE () {
      if (!!window.ActiveXObject || "ActiveXObject" in window) return true;
      else return false;
    },
    /** 界面重渲染 */
    changeFrameWH () {
      let self = this;
      let list = [...self.maxVideoNum];
      self.maxVideoNum = [];
      self.maxVideoNum = [...list];
    },    
    /**
     * 历史视频暂停播放bug修复
     */
    historyVideoUpdata (key, histoycurrentTime) {
      let nowTime = Date.now();
      let gapTime = 1000;
      let player = this.playerMap.get(key);
      if (player) {
        //histoycurrentTime：单位：毫秒
        // this.historyVideo.currentTime = parseInt(histoycurrentTime / 1000);//单位：秒
        //this.historyVideo.currentTime = parseInt(this.historyVideo.historyTime / 1000);
        this.historyVideo.currentTime = parseInt(histoycurrentTime / 1000) + this.historyVideo.dragTime;
        if (
          this.historyVideo.currentTime ===
          this.formateTimeRound(this.timeRound, true)
        ) {
          setTimeout(() => {
            this.flv_destroy(key, true);
          }, 1000);
        }
        this.historyVideo.lastTime = nowTime;
      }
    },
    /** 历史视频 拖拽进度条 */
    changeVideoTime (val, key) {
      console.log("val", val);
      // 改变视频时间
      if (this.firstLoadHistory || this.historyVideo.currentTime === 0) return;
      this.historyVideo.drag = true;
      this.historyVideo.historyTime = val * 1000; // 暂存之前时间，单位：毫秒
      this.historyVideo.currentTime = val;//当前播放时间，单位秒
      this.historyVideo.dragTime = val;
      let player = this.playerMap.get(key);
      if (player) {
        // 拿到时间，去调用服务器端的seek接口
        this.$emit("dragChangeVideoTime", val);//拿到数据,下发拖动更新指令
      }
      // this.flv_destroy(1);
      // this.$emit("dragChangeVideoTime", val);
    },
    /** 参数还原 */
    initParameter () {
      this.historyVideo.drag = false;
      this.historyVideo.currentTime = 0;
      this.historyVideo.historyTime = 0;
      this.historyVideo.dragTime = 0;
      this.firstLoadHistory = true;
      this.isOpen = true; // 正在播放
      this.isMuted = true; // 正在静音
      this.isFullScreen = true; // 正在全屏
      this.fullscreen = false; // 全画面全屏
    },
    /** 时间转换 */
    formatTimeBySeconds (time) {
      return YHTools.FormatTimeBySeconds(time)
        ? YHTools.FormatTimeBySeconds(time)
        : "00:00";
    },

    /** 时间间隔转换 */
    formateTimeRound (timeRound, flag) {
      let time = "";
      let timeDiff = "";
      let date = "";
      time = timeRound ? timeRound.split(" 至 ") : "";
      timeDiff = new Date(time[1]).getTime() - new Date(time[0]).getTime();
      if (flag) return parseInt(timeDiff / 1000);//可以获取历史视频时长时间戳
      date = YHTools.FormatTimeBySeconds(timeDiff / 1000)
        ? YHTools.FormatTimeBySeconds(timeDiff / 1000)
        : "00:00";
      return date;
    },
    /** 多切少，关闭播放 */
    closePlayerNum (index) {
      console.log("多切少");
      for (let i = index; i < this.playVideoList.length; i++) {
        const element = this.playVideoList[i];
        if (element) {
          this.flv_destroy(i + 1);
        }
      }
    },
    /**视频自动停止播放 */
    checkPlayOnTime () {
      let self = this;
      let limiTime = 5; //最多观看5分钟
      let flag = this.nowMenuList.indexOf("可观看长时间实时视频") >= 0;
      // console.log("this.nowMenuList", this.nowMenuList);
      if (flag) return;
      if (this.nowMenuList.indexOf("可观看") > -1) {
        //有做观看时长限制
        let nowMenuList = this.nowMenuList.split(",");
        // console.log("nowMenuList", nowMenuList);
        let allowPlayVideoList = nowMenuList.filter(item => item.indexOf("可观看") > -1);//视频时长权限数组
        let timeStr = allowPlayVideoList.join(",").replace(/,/g, "").replace(/分钟实时视频/g, "");
        let timeList = timeStr.split("可观看");
        console.log("timeList", timeList);
        timeList.shift();
        let max = timeList.reduce(function (a, b) {
          return Number(b) > Number(a) ? Number(b) : Number(a);
        });
        limiTime = max;
        console.log("showVideoTime----:", limiTime);
      }

      if (self.timer_time) {
        clearInterval(self.timer_time);
      }
      let timerFn = function () {
        if (self.playVideTimeObj.size == 0) {
          return;
        }
        self.playVideTimeObj.forEach((item, key) => {
          let time = new Date();
          let timeDiff = (time.getTime() - item.getTime()) / 60 / 1000;

          if (timeDiff >= limiTime) {
            console.log("实时视频时长权限超出提示：", limiTime);
            //超过可播放时限
            let index = self.playVideoList.findIndex(value => value == key);
            if (index >= 0) self.flv_destroy(index + 1, true);
          }
        });
      };
      self.timer_time = setInterval(() => {
        timerFn();
      }, 5000);
    },
    setVideoHeartbeat () {
      let self = this;
      if (self.timer_heartbeat) {
        clearInterval(self.timer_heartbeat);
      }
      let timerFn = function () {
        if (self.playVideTimeObj.size == 0) {
          return;
        }
        let data = {
          userId: self.$store.state.user.userId,
          channels: self.playVideTimeObj.size,
          token: TripleDES.encode(`yinghairuitekeji_${getCurent()}`)
        };
        heartbeat(data).then();
      };
      self.timer_heartbeat = setInterval(() => {
        timerFn();
      }, 5000);
    }
  },
  watch: {
    buttonValue (val, oldVal) {
      // console.log("+++++++buttonValue", val);
      let self = this;
      let maxVideoNum = [];
      for (let i = 1; i <= val; i++) {
        let element = self.maxVideoNum[i - 1];
        let flag = element ? element[i] : false;
        let currentTime = element ? element.currentTime : 0;
        let isOpen = element ? element.isOpen : false;
        let isShow = element ? element.isShow : false;
        let isMuted = element ? element.isMuted : true;
        let plate = element ? element.plate?.replace("摄像头", "CH") : "";
        //网速
        let kBps = element ? element.kBps : 0;
        maxVideoNum.push({
          [i]: flag,
          currentTime: currentTime,
          isOpen: isOpen,//是否正在播放
          isMuted: isMuted,
          isShow: isShow,
          plate: plate,
          kBps: kBps,
        });
      }
      self.maxVideoNum = [...maxVideoNum];
      // console.log("+++++maxVideoNum", maxVideoNum);
    },
    playVideoList (val, oldVal) {
      // console.log("this.playVideoList,传到父级，告知父级当前播放的是哪些", val);
      //传到父级，告知父级当前播放的是哪些
      if (!this.isHistory) this.$emit("currentPlayData", this.playVideoList);
    }
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    let self = this;
    if (!self.isHistory) clearInterval(self.timer_time);
    clearInterval(self.timer_heartbeat);
    window.removeEventListener("resize", self.hanleResize);
  }
};
</script>

<style lang="scss" scoped>
.videoList {
  border: 0px;
  position: absolute;
  overflow: hidden;
}

.videoDiv {
  overflow: hidden;
}

// .videoScreen {
//   position: absolute;
//   z-index: 999;
//   top: 1px;
//   left: 1px;
//   right: 1px;
//   bottom: 1px;
//   background: url('../../../../assets/images/video/background.png') repeat
//     center;
//   background-size: 100% 100%;
// }

.videoPlayBtn {
  cursor: pointer;
  z-index: 1010;
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -32px; /* 高度的一半 */
  margin-left: -32px; /* 宽度的一半 */
  width: 64px; /* 要设定宽度 */
  height: 64px;
  background: no-repeat center center;
}

.video {
  width: 100%;
  height: 100%;
  position: relative;
  // border-bottom: #ecf5ff;
  border: #616161 solid 0.5px;
  box-sizing: border-box;
  .videoObj {
    width: 100%;
    height: 100%;
    object-fit: fill;
    // background: #2c2f31;
    background: url('../../../../assets/images/video/background.png') repeat
      center;
    background-size: 100% 100%;
  }
}
.videoList {
  .selected {
    border: 1px solid #ff0000;
    top: 0px;
    left: 0px;
    right: 2px;
    bottom: 2px;
  }
  .fill {
    object-fit: fill;
  }
  // .cover {
  //   object-fit: contain;
  // }
  .videoInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 20px;
    right: 0;
    height: auto;
    line-height: normal;
    padding: 10px;
    text-align: center;
    font-weight: 200;
    color: #ffffff;
    font-size: 14px;
    z-index: 1005;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0,
      #000 0 -1px 0;
    -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
  .errInfo {
    font-weight: 300;
    color: red;
    font-size: 18px;
  }
}
//新视频底部样式调整
.videoCtrlBottom2 {
  position: absolute;
  bottom: 8px;
  left: 100px;
  right: 0;
  text-align: left;
  z-index: 1000;
  padding: 2px 5px;
  color: #fff;
  width: max-content;
  .carPlate {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
  .carPlate.text-small {
    font-size: 10px;
  }
}
/**底部控制栏 */
.videoCtrlBottom,
.videoControl {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 1000;
  // background: #303133;
  // opacity: 0.8;
  background: #000;
  font-weight: 200;
  padding: 2px 5px;
  color: #fff;

  .progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 24px;
      width: 35px;
    }
    i.pause-small {
      font-size: 12px;
      width: 20px;
    }
  }
  .rightctr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  a {
    width: 3vh;
    height: 2vh;
    padding-right: 2px;
  }
  .mutedVideo {
    background: url('../../../../assets/images/video/voice.png') no-repeat
      center;
    background-size: 70%;
  }
  .isMutedVideo {
    background: url('../../../../assets/images/video/isMuted.png') no-repeat
      center;
    background-size: 70%;
  }
  .captureVideo {
    background: url('../../../../assets/images/video/cutPicture.png') no-repeat
      center;
    background-size: 70%;
  }
  .recordVideo {
    background: url('../../../../assets/images/video/record.png') no-repeat
      center;
    background-size: 70%;
  }
  .quanpcarVideo {
    background: url('../../../../assets/images/video/fullscreen.png') no-repeat
      center;
    background-size: 70%;
  }
  .closecarVideo {
    background: url('../../../../assets/images/video/close.png') no-repeat
      center;
    background-size: 70%;
  }
}
.videoCtrlBottom {
  height: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .carVideoName2 {
    float: left;
    color: #ffffff;
    font-weight: 200;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    width: auto;
    z-index: 1001;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0,
      #000 0 -1px 0;
    -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
  .carPlate {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
  .carPlate.text-small {
    font-size: 10px;
  }
}
.videoControl {
  height: 68px;
  .el-slider {
    padding: 0 15px;
  }
  .progress {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.termileP {
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 10%;
  width: 100%;
  z-index: 1000;
  color: #36ad1d;
  font-size: 16px;
}

.videoScreen .carVideoName {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  font-weight: 300;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  width: auto;
  z-index: 1001;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
}
</style>
<style>
#VidedList video::-webkit-media-controls-enclosure {
  visibility: hidden;
}
</style>
