<template>
  <el-container class="historyVideo" :style="cardWidth[this.$store.state.vehicle.screenCoefficient]" v-loading="loading">
    <div class="box-card" :style="cardWidth[this.$store.state.vehicle.screenCoefficient]">
      <div class="card-header">
        <p class="clearfix">
         
          <section> <span>选择车辆：</span>
          <car-tree
          ref="carSearchTree"
          label="id"
          :isHistory="true"
          @getData="getVehIds"
          :choosedCph="choosedCph"
        ></car-tree></section>
          <span>
            <el-button
              type="text"
              size="small"
              v-for="i of videoNumber"
              :sort="channel === i ? '' : 'grey'"
              :key="i"
              @click="handleChannelClick(i)"
              ><i class="iconfont its_shexiangtou"></i>0{{ i }}</el-button>
          </span>
        </p>

        <report-form :selectCar="false" @getFormVal="getFormVal" />
      </div>
      <div id="fullscreen" class="card-body">
        <p>查询结果：</p>
        <el-table :data="getFileInfo" stripe height="200">  
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openHistoryVideo(scope.row)" sort="primary">
                <i class="iconfont its_icon_bofang"></i>
              </el-button>
              <el-button type="text" size="small" @click="downLoad(scope.row)" sort="primary">
                <i class="iconfont its_xiazaizhongxin"></i>
              </el-button>
            </template>
          </el-table-column>    
          <el-table-column prop="val" label="时间段" width="320"></el-table-column>
          <el-table-column prop="fileSize" label="大小(MB)" width="120" :show-overflow-tooltip="true">
              <template slot-scope="scope">
             {{scope.row.fileSize | formataSize}}
            </template>
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-button @click="onViewTour(scope.row)" type="text" size="small" sort="primary">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-container>
</template>

<script>
import { formatDate, returnVideoArr } from '@/common/utils/index'
import {
  checkVideoStreams,
  addSpotCheck,
  historyVideoCheck
} from '@/api/lib/refreshAlarm'
import reportForm from './downloadLimit/form'
// import videoList from '../realVideo/videoList'

import carTree from '@/components/carTree/carSelectTree.vue'

export default {
  components: {
    reportForm,
    // videoList,
    carTree
  },
  name: 'historyVideo',
  props: {
    id: {
      type: [String, Number]
    },
    videoStyle: {
      type: String
    }
  },
  data () {
    return {
      choosedCph: '', //选择车辆回显-点击树-点击面板
      form: {
        startTime: null, // 开始时间
        endTime: null,
        currentPage: 1,
        pageSize: 10,
        type: 1
      },
      buttonValue: 0,
      vehicleId: '',
      getFileInfo: [], // 请求回来的视频资源
      showFileList: false,
      timeRound: '',
      videoNumber: 0, //摄像头个数
      device: '', // 终端号
      channel: null, // 通道号 |当前播放的摄像头
      plate: '', // 当前选中车辆
      iframeWin: {},
      params: {}, // 传到视频界面参数
      beginTime: 0,
      endTime: 0,
      openStatus: false, // 请求播放的状态
      loading: false, // 点击查询loading
      timeExit: null, //
      Timeout: false, // 播放请求超过时间还没有返回
      openTimeOut: null, // 请求播放飞返回提示定时器
      cardWidth: {
        1.3: 'width:36vw;',
        1.15: 'width:45vw;',
        1: 'width:50vw;'
      }, // 整个面板的宽度
      Multiple: 0,
      dialogDownloadVisible: false, // 下载时间选择显示
      dowloadParams: null,
      // showVideoControl: false,
    }
  },
  filters:{
    formataSize(v){
      return (v/1024/1024).toFixed(2)
    }
  },
  methods: {
    /** 最小化视频 */
    // closeDialog (e) {
    //   this.$emit('closeDialog')
    // },

    /** 实时监听历史视频是否返回视频资源    下发播放指令是否应答  20191112 */
    setEventListener () {
      this.$yhsdp.AddRefreshCallBack(
        this.$RefreshTypeEnum.GPS_QueryTerminalResList,
        this.getGPS_QueryTerminalResList
      )
      this.$yhsdp.AddRefreshCallBack(
        this.$RefreshTypeEnum.GPS_HistoryVideo,
        this.getGPS_HistoryVideo
      )
    },

    /** 点击查询 查询当前车辆的视频数据 */
    getFormVal (v) {
      const { address,alarmType,streamType,videoType,startTime,endTime} = v
      this.form.startTime = startTime
      this.form.endTime = endTime
      if (this.vehicleId === '') {
        this.$message.warning('请点击对应车辆摄像头')
        return
      }
      if (!this.channel) {
        this.$message.warning('请点击对应车辆摄像头')
        return
      }
      if (this.openStatus) {
        this.$message.warning('该视频正在播放，请先关闭再去查询')
        return
      }
      this.loading = true
      this.params.device = this.device // 终端号
      this.params.channel = this.channel // 通道号 （1~8）数组
      this.params.plate = this.plate // 车牌号（可选）

      this.params.begintime = new Date(this.form.startTime).getTime() / 1000 // 开始时间
      this.params.endtime = new Date(this.form.endTime).getTime() / 1000
      this.params.channelObj = {
        channels: [`${this.device}_channel_${this.channel}_playback`]
      }
      this.params.address = address
      this.params.alarmType = alarmType
      this.params.streamType = streamType
      this.params.videoType = videoType
      this.checkPlayerExit()
    },

    /** 切换播放画面 */
    setPlayerNum (index) {
      this.buttonValue = index
    },
    /** 切换播放channel */
    handleChannelClick (index) {
      if (this.Timeout || this.openStatus) this.close(true)
      this.openStatus = false
      this.getFileInfo = []
      this.timeRound = ''

      this.showFileList = false
      this.loading = false
      clearTimeout(this.timeExit)
      this.channel = index
      //选中id切换
      this.$emit(
        'handleChannelClick',
        `${this.vehicleId}_${this.plate}摄像头${this.channel}`
      )
    },
    //显示界面
    showVideo () {
      this.buttonValue = 1
    },
    //面板点击-显示界面-选中车辆
    toHandlePlayHisVideo (data) {
      if (this.Timeout || this.openStatus) this.close(true)
      this.buttonValue = 1
      this.vehicleId = '' // 车辆id
      this.openStatus = false
      this.getFileInfo = []
      this.showFileList = false
      this.loading = false
      clearTimeout(this.timeExit)

      this.timeRound = ''
      this.vehicleId = data.id // 车辆id
      this.plate = data.name // 车牌号
      this.choosedCph = this.plate
      this.device = data.telNo //终端号
      this.channel = null
      this.videoNumber = Number(returnVideoArr(data.videos)) ///摄像头个数
    },
    /** 点击事件 */
    handleClick (d, parent, flag) {
      if (this.Timeout || this.openStatus) this.close(true)
      this.vehicleId = '' // 车辆id
      this.openStatus = false
      this.getFileInfo = []
      this.showFileList = false
      this.loading = false
      clearTimeout(this.timeExit)

      if (flag === 'close') return
      this.timeRound = ''
      this.vehicleId = d.id.split('_')[0] // 车辆id
      this.plate = parent.name // 车牌号
      this.choosedCph = this.plate

      this.device = d.telNo //终端号
      this.channel = Number(d.name.substr(d.name.length - 1, 1))
      this.videoNumber = Number(returnVideoArr(parent.videos)) ///摄像头个数
    },
    /** 确认播放通道是否打开 */
    checkPlayerExit () {
      checkVideoStreams(this.params.channelObj).then((res) => {
        if (res.code === 1000) {
          if (res.data.localPush.length > 0 || res.data.noPush.length > 0) {
            let carName =
              res.data.localPush[0] || res.data.noPush[0] || res.data.push[0]
            // this.$yhsdp.GBQueryTerminalResList(this.params)
            this.$yhsdp.QueryTerminalHisList(this.params)
            this.timeExit = setTimeout(() => {
              this.loading = false
              if (!this.openStatus) {
                // this.$message.warning(
                //   `${carName.split('_')[0]}超时未应答，请检查网络情况后再试！`
                // )
                //2023.3.1谢更改提示语、误导客户(产品孙俊确认)
                // this.$message.warning('暂无视频数据')
              }
            }, 1000*20)
          } else {
            this.loading = false
            this.$message.warning(
              `车辆${this.params.plate}通道${this.channel}有其他用户在观看`
            )
          }
        }
      })
    },
    /** 实时监听历史视频是否返回视频资源 */
    getGPS_QueryTerminalResList (data) {
      let self = this
      this.getFileInfo = []
      this.loading = false
      clearTimeout(this.timeExit)
      if (!data || data.length === 0) {
        this.$message.warning('暂无视频数据')
        return
      }

      this.showFileList = true
      data.forEach(function (value, index) {
        let stime = new Date(value.beginTime)
        let etime = new Date(value.endTime)
        let optionVal = `${formatDate(stime)} 至 ${formatDate(etime)}`
        value.val = optionVal
        self.getFileInfo.unshift(value)
      })
      // this.timeRound = self.getFileInfo[0].val
      // this.beginTime = new Date(self.getFileInfo[0].beginTime).getTime() / 1000
      // this.endTime = new Date(self.getFileInfo[0].endTime).getTime() / 1000
      // this.resType = self.getFileInfo[0].resType // 音视频资源类型（0：音视频 1：音频 2：视频）
      // this.streamType = self.getFileInfo[0].streamType // 码流类型（1：主码流 2：子码流）
      // this.storageType = self.getFileInfo[0].storageType // 存储器类型（1：主存储器 2：灾备存储器）
      // this.channel = self.getFileInfo[0].channel
      // self.$refs.flvPlayModule.changeFrameWH()
      // this.setPlayerNum(1);
    },

    /** 切换当前播放文件时间段----以前的下拉展示播放历史视频 */
    pickOption (vId) {
      clearTimeout(this.timeExit)
      clearTimeout(this.openTimeOut)
      this.close(true) // 不去掉当前播放状态
      if (!vId) {
        this.beginTime = ''
        this.endTime = ''
        return
      }
      let obj = {}
      obj = this.getFileInfo.find((item) => {
        return item.val === vId // 筛选出匹配数据
      })
      this.beginTime = new Date(obj.beginTime).getTime() / 1000
      this.endTime = new Date(obj.endTime).getTime() / 1000
      this.resType = obj.resType // 音视频资源类型（0：音视频 1：音频 2：视频）
      this.streamType = obj.streamType // 码流类型（1：主码流 2：子码流）
      this.storageType = obj.storageType // 存储器类型（1：主存储器 2：灾备存储器）
      this.channel = obj.channel
    },
    /** 请求历史视频返回结果 */
    getGPS_HistoryVideo (data) {
      // console.log('----------------请求历史视频返回结果',data,this.beginTime)
      let self = this
      this.loading = false
      if (!data.flag) {
        this.$message.warning(`与${data.data} 网络连接失败`)
      }
      if (this.Timeout) return
      clearTimeout(this.timeExit)
      clearTimeout(this.openTimeOut)
      this.openStatus = true
      // self.$refs.flvPlayModule.initParameter()
      let params = {
        device: this.device, // 终端号
        channel: this.channel, // 通道号 （1~8）数组
        plate: this.plate // 车牌号（可选）
      }
      // this.showVideoControl = true
      this.$emit('initParameter',params)
      // this.$refs.flvPlayModule.playHistoryVideo(params)
    },
    /** 播放 */
    openHistoryVideo (item) {
      if (!this.showFileList) return
      clearTimeout(this.timeExit)
      clearTimeout(this.openTimeOut)
      // if (!this.beginTime) {
      //   this.$message.warning('请选择一个时间段')
      //   return
      // }
      if (this.openStatus) {
        this.$message.warning('该视频正在播放，请先关闭再去重新请求')
        return
      }
      // this.$refs.flvPlayModule.changeFrameWH()
      this.$emit('playHistoryVideo',item,this.plate,this.vehicleId,new Date(item.beginTime).getTime() / 1000)
      // this.loading = true
      this.Timeout = false
      //调用添加抽查信息
      this.addSpotCheck()
      // this.$yhsdp.GBHistoryStreamReq(
      //   this.device,//终端号
      //   this.channel,
      //   this.beginTime,
      //   this.endTime,
      //   this.resType,
      //   this.streamType,
      //   this.storageType,
      //   this.Multiple
      // )
      this.$yhsdp.GBHistoryStreamReq(
        this.device,//终端号
        item.channel,
        new Date(item.beginTime).getTime() / 1000,
        new Date(item.endTime).getTime() / 1000,
        item.resType,
        item.streamType,
        item.storageType,
        this.Multiple//// 快进或快退倍数
      )
      this.openTimeOut = setTimeout(() => {
        // this.loading = false
        if (!this.openStatus) {
          this.Timeout = true // 已执行过
          this.$message.warning('查询终端视频超时，请检查您的网络环境再试');
        }
      }, 15000)
    },
    //添加抽查信息
    addSpotCheck () {
      let system = sessionStorage.getItem('system')
      let deviceType = system === "2" ? "0" : system

      addSpotCheck({
        type: 3,
        ids: this.vehicleId,
        system,
        deviceType
      }).then()
    },
    /** 停止 */
    close (flag) {
      if (!this.showFileList) return
      clearTimeout(this.timeExit)
      clearTimeout(this.openTimeOut)
      if (this.params && this.openStatus) {
        this.$yhsdp.GBHistoryStreamControl(this.params)
      }
      this.openStatus = false;
      this.Timeout = true;
      // this.$refs.flvPlayModule.flv_destroyAll()
      if (!flag) this.$emit('close')
    },
    /* 点击页面上的关闭 */
    // handleCloseVideo (params) {
    //   /** 一般停止 */
    //   this.playVideoObj.delete(params)
    //   this.sendVideoObj.delete(params)
    //   let item = params.split('_channel_')
    //   this.$emit(
    //     'closePlay',
    //     `v${this.vehicleId}`,
    //     `${this.plate}摄像头${item[1]}`
    //   )
    // },
    /** 通道切换关闭当前播放 */
    // changeNodeClose () {
    //   clearTimeout(this.timeExit)
    //   clearTimeout(this.openTimeOut)
    //   if (this.params && this.openStatus) {
    //     this.$yhsdp.GBHistoryStreamControl(this.params)
    //   }
    //   this.Timeout = false
    //   this.openStatus = false
    //   this.$refs.flvPlayModule.flv_destroyAll()
    // },
    /** 拖动播放 */
    // dragChangeVideoTime (val) {
    //   this.$yhsdp.GBHistoryStreamSpeedControl(
    //     this.device,
    //     this.channel,
    //     this.beginTime + val
    //   )
    //   this.$refs.flvPlayModule.playHistoryVideo({
    //     device: this.device, // 终端号
    //     channel: this.channel, // 通道号 （1~8）数组
    //     plate: this.plate
    //   })
    // },    
    /** 下载 */
    downLoad (item) {
      if (!this.showFileList) return
      // if (!this.beginTime) {
      //   this.$message.warning('请选择一个时间段')
      //   return
      // }
      // let beginTime = formatDate(new Date(item.beginTime * 1000))
      // let endTime = formatDate(new Date(item.endTime * 1000))
      let params = {
        telNo: this.device,
        channelId: this.channel,
        startTime: item.beginTime,
        endTime: item.endTime
      }
      // console.log('历史视频下载：',params)
      this.$emit('downLoadHistoryVideo', params,item)
    },
    /**查看详情 */
    onViewTour(item){
      this.$emit('showDetail',item,this.plate)
    },
    /** 下载历史视频 */
    downLoadCheck (params,info) {
      let data = {
        telNo: this.device,
        channelId: this.channel,
        startTime: params.startTime,
        endTime: params.endTime
      }
      // console.log('历史视频下载最终参数：',data)
      historyVideoCheck({ fileTaskVo: data }).then((res) => {
        // console.log('请求历史视频下载',res)
        /**  状态：-1-失败，0-成功，2-上传中*/
        if (res.data.status === -1) {
          // debugger
          this.$yhsdp.downLoadHistoryFile(
            this.device,
            this.channel,
            params.startTime,
            params.endTime,
            info.resType,
            info.streamType,
            info.storageType
          )
        } else if (res.data.status === 0) {
          let url = res.data.filePath
          const elt = document.createElement('a')
          elt.setAttribute(
            'download',
            `设备${this.device} 摄像头${this.channel}_${params.startTime}-${params.endTime}.flv`
          )
          elt.setAttribute(
            'href',
            `${url}?response-content-type=application/octet-stream`
          )
          elt.style.display = 'none'
          document.body.appendChild(elt)
          elt.click()
          document.body.removeChild(elt)
        } else {
          this.$message.warning('终端正在上传，请稍后下载')
        }
      })
    },
    /** 退出界面 */
    // stopAllVideo () {
    //   if (this.Timeout || this.openStatus) this.close()
    //   this.$emit('stopAllVideo')
    // },
    /**选择车辆 */
    getVehIds (id, value) {
      if (this.Timeout || this.openStatus) this.close(true)
      this.buttonValue = 1
      this.vehicleId = '' // 车辆id
      this.openStatus = false
      this.getFileInfo = []
      this.showFileList = false
      this.timeRound = ''
      this.loading = false
      this.channel = null
      this.videoNumber = 0

      clearTimeout(this.timeExit)
      if (!value || value.length === 0) return
      let data = value[0]

      this.vehicleId = `v${data.id}` // 车辆id
      this.plate = data.cph // 车牌号
      this.device = returnVideoArr(data.videos, true) //终端号
      this.channel = null
      this.videoNumber = Number(returnVideoArr(data.videos)) ///摄像头个数
    }
  },

  created () { },
  mounted () {
    this.$nextTick(function () {
      this.setPlayerNum(1)
      this.setEventListener()
    })
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    if (this.Timeout || this.openStatus) this.close()
    clearTimeout(this.timeExit)
    clearTimeout(this.openTimeOut)
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_QueryTerminalResList,
      this.getGPS_QueryTerminalResList
    )
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_HistoryVideo,
      this.getGPS_HistoryVideo
    )
  }
}
</script>

<style lang="scss" scoped>
.historyVideo {
  position: absolute;
  border: 1px;
  top: 70px;
  right: 16px;
  z-index: 2000;
  background: #fff;
  .box-card {
    // height: 49vh;
    .card-body {
      // width: 100%;
      max-height: 35vh;
      margin: 5px 0;
      padding:10px 14px 20px;
    }
    .card-header {
      .clearfix {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4vh;
        padding: 0 10px;
        font-size: 16px;
        font-weight: bold;
        /deep/ .el-input__inner {
          height: 3.4vh;
          line-height: 3.4vh;
        }
      }
    }
    .file_list {
      height: 4vh;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/ .el-input--medium .el-input__inner {
        height: 3.4vh;
        line-height: 3.4vh;
      }
    }
  }
}
/deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text{
  height: 180px;
}
</style>

