<template>
  <div ref="interactiveContent" class="interactive-content">
    <el-table
      :data="
        interactiveArr.slice(
          (form.currentPage - 1) * form.pageSize,
          form.currentPage * form.pageSize
        )
      "
      style="width: 100%"
      class="no-blank"
      :height="tableHeight"
      stripe
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="sVehNo"
        label="车牌号"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="vehGroupName"
        label="所属公司"
        width="200"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="sCmdTxt"
        label="指令内容"
        width="170"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="sTime"
        label="指令发送时间"
        width="170"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="sNote"
        :formatter="filterContent"
        label="终端返回内容"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="receiveTime"
        width="170"
        label="终端返回时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <el-button
            @click="onCheckDetail(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="$store.state.vehicle.interactiveArr.length"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="详情"
      append-to-body
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="60%"
    >
      <contentForm :parameterObj="parameterObj" />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="dialogVisible = false"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import contentForm from "./contentForm";
import { mapGetters } from "vuex";

export default {
  components: {
    contentForm
  },
  data() {
    return {
      tableHeight: 0,
      form: {
        currentPage: 1,
        pageSize: 10
      },
      dialogVisible: false,
      parameterObj: {
        sCmdTxt: "",
        param: [],
        sNote: []
      }
    };
  },
  computed: {
    ...mapGetters({
      interactiveArr: "interactiveArr",
      screenCoefficient: "screenCoefficient"
    })
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.interactiveContent.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - paginationHeight - 10;
    },
    onCheckDetail(row) {
      this.parameterObj = { ...row };
      console.log(row);
      if (
        row.sCmdTxt.indexOf("设置终端参数") > -1 ||
        row.sCmdTxt.indexOf("文本信息下发") > -1 ||
        row.sCmdTxt.indexOf("设置IC卡参数") > -1 ||
        row.sCmdTxt.indexOf("屏蔽报警") > -1 ||
        row.sCmdTxt.indexOf("关闭报警声音") > -1 ||
        row.sCmdTxt.indexOf("云台控制") > -1 ||
        row.sCmdTxt.indexOf("查询车辆行驶记录") > -1
      ) {
        let sCmdTxt = row.sCmdTxt.split("_");
        this.parameterObj.sCmdTxt = sCmdTxt[0];
        this.parameterObj.param = sCmdTxt[1]
          .substring(0, sCmdTxt[1].length)
          .split(",");
      }
      //查询车辆行驶记录-采集指定的行驶速度记录
      row.sCmdTxt.indexOf("查询车辆行驶记录") > -1 &&
        (this.parameterObj.drivingRecords = row.imgId);
      /**实时拍照数据处理-多了图片id */
      row.sCmdTxt.indexOf("实时拍照") > -1 &&
        (this.parameterObj.sNote = row.imgId);
      /**实时拍照数据处理-多了图片id */
      this.dialogVisible = true;
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
    },
    filterContent(row, column, cellValue) {
      let data = "";
      if (
        typeof cellValue === "object" &&
        row.sCmdTxt.indexOf("查询终端参数") > -1
      ) {
        if (row.sNote.alarm) {
          //gps 数据处理
          row.sNote.alarm.forEach(item => {
            data += `${item.name}${item.value} `;
          });
          row.sNote.net.forEach(item => {
            data += `${item.name}${item.value} `;
          });
          row.sNote.other.forEach(item => {
            data += `${item.name}${item.value} `;
          });
        } else {
          //出租车数据处理
          row.sNote.forEach(item => {
            data += `${item.name}${item.value} `;
          });
        }
        return data;
      }
      if (
        typeof cellValue === "object" &&
        row.sCmdTxt.indexOf("查询终端属性") > -1
      ) {
        row.sNote.forEach(item => {
          data += `${item.name}${item.value} `;
        });

        return data;
      }
      return cellValue;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  }
};
</script>
<style lang="scss" scoped>
.interactive-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
p.title {
  height: 36px;
  font-weight: 600;
}
.instruction {
  padding: 10px;
  p {
    height: 36px;
  }
  .ParametersBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    p.parameters {
      width: calc(30% - 30px);
      .parametersVal {
        font-size: 14px;
        letter-spacing: -0.34px;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}
</style>
