var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "realTimeInfo", staticClass: "real-info" },
    [
      _c(
        "div",
        {
          ref: "menu",
          staticClass: "copyMenu",
          on: { mouseleave: _vm.mousemoveMenu, click: _vm.toCopy }
        },
        [_c("div", { staticClass: "item" }, [_vm._v("复制")])]
      ),
      _c(
        "el-table",
        {
          staticClass: "no-blank",
          staticStyle: { width: "100%" },
          attrs: {
            "row-class-name": _vm.rowBg,
            data: _vm.tableData,
            height: _vm.tableHeight,
            stripe: "",
            border: "",
            id: "realTable"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              width: 90 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "车队",
              width: 120 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "line",
              label: "线路",
              width: "100",
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              formatter: _vm.formatType,
              label: "车辆类型",
              "show-overflow-tooltip": true,
              width: "130"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gpsTime",
              label: "刷新时间",
              width: 140 * _vm.screenCoefficient,
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              label: "所在位置",
              width: "260",
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              label: "速度方向",
              width: "180",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "速度:" +
                          _vm._s(scope.row.velocity ? scope.row.velocity : 0) +
                          "km/h 方向:" +
                          _vm._s(scope.row.angle ? scope.row.angle : "无")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.formatStatus,
              prop: "status",
              label: "车辆状态",
              width: "90",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "定位状态",
              width: "150",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.locate === 1 ? "未定位" : "已定位")
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getLocateText(
                            scope.row.gps,
                            scope.row.beidou,
                            scope.row.glonass,
                            scope.row.galileo
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "component",
              label: "部件状态",
              width: "170",
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceNos",
              label: "终端编号",
              width: 120 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "runMile",
              label: "里程数",
              width: 90 * _vm.screenCoefficient,
              "show-overflow-tooltip": true,
              formatter: _vm.formatNull
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "signInState",
              label: "签登状态",
              width: "90",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.signInState ? scope.row.signInState : "无"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driverName",
              label: "驾驶员",
              width: "70",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.driverName ? scope.row.driverName : "无"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              label: "电话",
              width: "120",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.phone ? scope.row.phone : "无"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vehStatus",
              formatter: _vm.formatRunState,
              label: "运营维修状态",
              width: "120",
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _vm.$store.state.menu.nowMenuList.indexOf("实时信息导出") > -1
        ? _c(
            "el-button",
            {
              staticStyle: { position: "absolute", bottom: "5px", left: "5px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.getXLS }
            },
            [_vm._v("导出")]
          )
        : _vm._e(),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageSize,
              "current-page": _vm.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }