var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo-form-inline dialog-form ctm" }, [
    _vm._m(0),
    _c("div", { staticClass: "instruction" }, [
      _c("div", { staticClass: "ParametersBox" }, [
        _c("p", { staticClass: "parameters" }, [
          _c("span", [_vm._v("车牌号 ：")]),
          _c("span", { staticClass: "parametersVal" }, [
            _vm._v(_vm._s(_vm.parameter.sVehNo))
          ])
        ]),
        _c("p", { staticClass: "parameters" }, [
          _c("span", [_vm._v("终端号 ：")]),
          _c("span", { staticClass: "parametersVal" }, [
            _vm._v(_vm._s(_vm.parameter.terminalNo))
          ])
        ]),
        _c("p", { staticClass: "parameters" }, [
          _c("span", [_vm._v("指令 ：")]),
          _c("span", { staticClass: "parametersVal" }, [
            _vm._v(_vm._s(_vm.parameter.sCmdTxt))
          ])
        ])
      ]),
      _c("p", { staticClass: "title" }, [_vm._v("指令参数")]),
      _vm.parameter.param.length > 0
        ? _c(
            "div",
            { staticClass: "ParametersBox" },
            _vm._l(_vm.parameter.param, function(item, i) {
              return _c("p", { key: i, staticClass: "parameters" }, [
                _c("span", [_vm._v(_vm._s(item))])
              ])
            }),
            0
          )
        : _c("p", [_vm._v("无")]),
      _c("p", [_vm._v("下发时间 ： " + _vm._s(_vm.parameter.sTime))])
    ]),
    _vm._m(1),
    _c("div", { staticClass: "instruction" }, [
      _c("p", { staticClass: "title" }, [_vm._v("返回参数")]),
      _c("p", [_vm._v("返回时间 : " + _vm._s(_vm.parameter.receiveTime))]),
      _vm.parameter.sNote.length > 0 && typeof _vm.parameter.sNote === "object"
        ? _c(
            "div",
            { staticClass: "ParametersBox attr" },
            _vm._l(_vm.parameter.sNote, function(item, i) {
              return _c("p", { key: i, staticClass: "parameters" }, [
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c(
                  "span",
                  {
                    staticClass: "parametersVal",
                    attrs: { title: item.value }
                  },
                  [_vm._v(" " + _vm._s(item.value) + " ")]
                )
              ])
            }),
            0
          )
        : _vm.parameter.sNote.alarm && typeof _vm.parameter.sNote === "object"
        ? _c("div", [
            _c("p", { staticClass: "title" }, [_vm._v("门限配置")]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              [
                _vm._l(_vm.parameter.sNote.alarm, function(item, i) {
                  return _c("p", { key: i, staticClass: "parameters" }, [
                    _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                    _c(
                      "span",
                      {
                        staticClass: "parametersVal",
                        attrs: { title: item.value }
                      },
                      [_vm._v(" " + _vm._s(item.value) + " ")]
                    )
                  ])
                }),
                _c("p", { staticClass: "parameters" }, [
                  _c("span", [_vm._v("夜间限速比例(%) ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: _vm.other.nightSpeedLimitPer }
                    },
                    [_vm._v(" " + _vm._s(_vm.other.nightSpeedLimitPer) + " ")]
                  )
                ]),
                _c("p", { staticClass: "parameters" }, [
                  _c("span", [_vm._v("白天时间段 ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: _vm.other.period }
                    },
                    [_vm._v(" " + _vm._s(_vm.other.period) + " ")]
                  )
                ])
              ],
              2
            ),
            _c("p", { staticClass: "title" }, [_vm._v("网络参数")]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              _vm._l(_vm.parameter.sNote.net, function(item, i) {
                return _c("p", { key: i, staticClass: "parameters" }, [
                  _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: item.value }
                    },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                ])
              }),
              0
            ),
            _vm.system !== "1"
              ? _c("p", { staticClass: "title" }, [_vm._v("屏蔽报警")])
              : _vm._e(),
            _vm.system !== "1"
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.shieldingAlarm, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.system !== "1"
              ? _c("p", { staticClass: "title" }, [_vm._v("关闭报警声音")])
              : _vm._e(),
            _vm.system !== "1"
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.closeAlarmVoice, function(
                    item,
                    i
                  ) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("p", { staticClass: "title" }, [_vm._v("音视频参数")]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              _vm._l(_vm.parameter.sNote.audioAndVideo, function(item, i) {
                return _c("p", { key: i, staticClass: "parameters" }, [
                  _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: item.value }
                    },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                ])
              }),
              0
            ),
            _c("p", { staticClass: "title" }, [_vm._v("驾驶员辅助功能参数")]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              _vm._l(_vm.parameter.sNote.adas, function(item, i) {
                return _c("p", { key: i, staticClass: "parameters" }, [
                  _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: item.value }
                    },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                ])
              }),
              0
            ),
            _c("p", { staticClass: "title" }, [
              _vm._v("驾驶员行为监测功能参数")
            ]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              _vm._l(_vm.parameter.sNote.dsm, function(item, i) {
                return _c("p", { key: i, staticClass: "parameters" }, [
                  _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: item.value }
                    },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                ])
              }),
              0
            ),
            _vm.parameter.sNote.tire.length > 0
              ? _c("p", { staticClass: "title" }, [
                  _vm._v(" 轮胎状态监测参数设置 ")
                ])
              : _vm._e(),
            _vm.parameter.sNote.tire.length > 0
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.tire, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.parameter.sNote.blind.length > 0
              ? _c("p", { staticClass: "title" }, [
                  _vm._v(" 盲区监测参数设置 ")
                ])
              : _vm._e(),
            _vm.parameter.sNote.blind.length > 0
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.blind, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.parameter.sNote.fierce.length > 0
              ? _c("p", { staticClass: "title" }, [
                  _vm._v(" 激烈驾驶报警配置 ")
                ])
              : _vm._e(),
            _vm.parameter.sNote.fierce.length > 0
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.fierce, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.parameter.sNote.limitHeight.length > 0
              ? _c("p", { staticClass: "title" }, [_vm._v(" 载重限高参数 ")])
              : _vm._e(),
            _vm.parameter.sNote.limitHeight.length > 0
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.limitHeight, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.parameter.sNote.night.length > 0
              ? _c("p", { staticClass: "title" }, [_vm._v(" 夜间限速参数 ")])
              : _vm._e(),
            _vm.parameter.sNote.night.length > 0
              ? _c(
                  "div",
                  { staticClass: "ParametersBox" },
                  _vm._l(_vm.parameter.sNote.night, function(item, i) {
                    return _c("p", { key: i, staticClass: "parameters" }, [
                      _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                      _c(
                        "span",
                        {
                          staticClass: "parametersVal",
                          attrs: { title: item.value }
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("p", { staticClass: "title" }, [_vm._v("其他参数")]),
            _c(
              "div",
              { staticClass: "ParametersBox" },
              _vm._l(_vm.parameter.sNote.other, function(item, i) {
                return _c("p", { key: i, staticClass: "parameters" }, [
                  _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
                  _c(
                    "span",
                    {
                      staticClass: "parametersVal",
                      attrs: { title: item.value }
                    },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                ])
              }),
              0
            )
          ])
        : _vm.parameterObj.sCmdTxt === "实时拍照" &&
          _vm.parameter.sNote.length > 0
        ? _c(
            "div",
            { staticClass: "real-img" },
            _vm._l(_vm.imgurl, function(item, index) {
              return _c("div", { key: index }, [
                _c("img", { attrs: { src: item.url, alt: "" } })
              ])
            }),
            0
          )
        : _c("p", [_vm._v(_vm._s(_vm.parameter.sNote))]),
      _vm.parameterObj.sCmdTxt === "查询车辆行驶记录" &&
      _vm.parameter.drivingRecords &&
      _vm.parameter.drivingRecords.length > 0
        ? _c("div", { staticClass: "drivingRecords-box" }, [
            _c(
              "p",
              [
                _vm._v("详情 : "),
                _c("el-button", { on: { click: _vm.exportFun } }, [
                  _vm._v("导出")
                ])
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("指令内容")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("返回内容")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }