<template>
  <div class="pl-table">
    <div class="top-btns">
      <div class="left">
        <el-button
          @click="AlarmRefresh(0)"
          size="small"
          :type="currentTab === 0?'primary':'info'"
          plain
          class="sm-export"
        >查看轨迹点</el-button>
        <el-button
          @click="AlarmRefresh(1)"
          size="small"
          :type="currentTab === 1?'primary':'info'"
          plain
          class="sm-export"
        >查看报警定位点</el-button>
        <el-button
          @click="AlarmRefresh(2)"
          size="small"
          :type="currentTab === 2?'primary':'info'"
          plain
          class="sm-export"
        >查看停车点</el-button>
      </div>
      <div class="righht">
        <span class="el-pagination__total">共 {{ tableTotal }} 条</span>
        <el-button
          v-if="currentTab === 0"
          :disabled="historyTrackListLength === 0"
          :loading="downLoadStatus"
          @click="epxortTrail(false)"
          size="small"
          type="primary"
          class="sm-export"
        >导出</el-button>
        <el-button
          v-if="currentTab === 0"
          :disabled="historyTrackListLength === 0"
          :loading="downLoadStatus"
          @click="epxortTrail(true)"
          size="small"
          type="primary"
          class="sm-export"
        >解析地址导出</el-button>

        <el-button
          v-if="currentTab === 2"
          :disabled="stopsDataLength === 0"
          :loading="downLoadStatus"
          @click="epxortTrailStops"
          size="small"
          type="primary"
          class="sm-export"
        >导出</el-button>
      </div>
    </div>
    <pl-table
      v-if="currentTab !==2"
      :data="currentTab===0?trailTableData:alarmData"
      :stripe="true"
      :resizable="false"
      :height="multipleTableHeight"
      :border="false"
      :row-class-name="rowColor"
      :highlight-current-row="false"
      ref="multipleTable"
      :row-height="45"
      :excess-rows="3"
      auto-resize
      @row-click="locatePoint"
      v-el-table-infinite-scroll="load"
    >
      <pl-table-column width="60">
        <template slot="header">
          <span @click="enlargeTable">
            <i class="el-icon-top head-icon"></i>
          </span>
          <span @click="closeTable">
            <i class="el-icon-bottom head-icon"></i>
          </span>
        </template>
        <template slot-scope="{ $index }">
          <span>{{ (page - 1) * pageSize + $index + 1 }}</span>
        </template>
      </pl-table-column>
      <pl-table-column prop="gt" label="时间" :width="180" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="dn" label="驾驶员" :width="160" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="sl" label="速度/限速值" :width="100" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="a" label="方向" show-overflow-tooltip></pl-table-column>
      <pl-table-column label="定位状态" prop="l" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
          scope.row.l === null || scope.row.l === 1
          ? "未定位"
          : "已定位"
          }}
        </template>
      </pl-table-column>
      <pl-table-column prop="dt" label="是否补传" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
          scope.row.dt === null || scope.row.dt === 3
          ? "异常":scope.row.dt === 0 ?"正常":scope.row.dt === 1 ?"补报":scope.row.dt === 2?"迁移":"屏蔽"
          }}
        </template>
      </pl-table-column>
      <pl-table-column prop="m" label="里程数" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="rm" label="相对里程" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="c" label="部件状态" :width="240" show-overflow-tooltip></pl-table-column>
      <!-- <pl-table-column
        prop="address1"
        label="位置"
        :width="280"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="addressStyle">{{ scope.row.address1 }}</span>
        </template>
      </pl-table-column>-->
      <pl-table-column prop="ac" label="报警信息" :width="160" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="ori" label="原始报文" :width="200" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="es" label="电子传感器速度" :width="120" show-overflow-tooltip></pl-table-column>
    </pl-table>
    <pl-table
      v-else
      :data="stopsData"
      :stripe="true"
      :resizable="false"
      :height="multipleTableHeight"
      :cell-style="tableCellClassName"
      :border="false"
      :highlight-current-row="false"
      ref="multipleTableStop"
      :row-height="45"
      :excess-rows="3"
      auto-resize
      @row-click="locatePoint"
      v-el-table-infinite-scroll="load"
    >
      <pl-table-column width="60">
        <template slot="header">
          <span @click="enlargeTable">
            <i class="el-icon-top head-icon"></i>
          </span>
          <span @click="closeTable">
            <i class="el-icon-bottom head-icon"></i>
          </span>
        </template>
        <template slot-scope="{ $index }">
          <span>{{ (page - 1) * pageSize + $index + 1 }}</span>
        </template>
      </pl-table-column>
      <pl-table-column prop="startTime" label="开始时间" width="180" show-overflow-tooltip></pl-table-column>
      <pl-table-column prop="endTime" label="结束时间" width="200"></pl-table-column>
      <pl-table-column prop="pkt" label="停车时长" width="120" show-overflow-tooltip>
      </pl-table-column>
      <pl-table-column prop="address" label="地理位置" show-overflow-tooltip></pl-table-column>
    </pl-table>
    <!-- <div class="pagination">
      <span class="el-pagination__total">共 {{ tableTotal }} 条</span>
      <el-radio-group v-model="radio" size="mini" @change="selectTime">
        <el-radio-button
          v-for="(time, index) in timeArr"
          :key="index"
          :label="time"
          type="primary"
        ></el-radio-button>
      </el-radio-group>
    </div>-->
  </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { PlTable, PlTableColumn } from "pl-table";
import {
  getHistoryTrack,
  getQueryTimeSection,
  gethistoryTrackNew,
  positionCount,
  addExcelExport,
} from "@/api/lib/refreshAlarm.js";
import { formatDay, getCurent, oneGpsToLngLat } from "@/common/utils/index";
import getPlace from '@/common/utils/getPlace.js'

export default {
  name: "pagination",
  components: {
    PlTable,
    PlTableColumn,
  },
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  props: {
    /** 当前系统参数 */
    system: {
      type: [Number, String]
    },
  },
  data () {
    return {
      paramData: {},
      buttonFlag: 1, // 展开轨迹表
      alarmData: [],//
      bigTrailTable: "half", // 轨迹表格高度
      trailTableData: [], // 轨迹表格      
      trailTableDataBase: [], // 轨迹表格数据仓库
      alarmDataBase: [],//报警数据仓库
      stopsData: [],//停靠点数据
      stopsDataBase: [],//停靠点原始数据
      tableLoading: false, // 轨迹表格加载
      tableTotal: 0, // 轨迹表格total
      trailTableStatus: false, // 轨迹表格正在加载
      loadMoreEnd: true, // 无限滚动是否结束
      queryStartTime: "", // 轨迹回放查询起始时间
      queryEndTime: "", // 轨迹回放查询结束时间
      isQueryEnd: false, // 轨迹回放查询结果是否小余100
      isLoadOver: false, // 轨迹回放是否可继续滚动加载
      changeTimeOver: true, // 切换时间时过程中不请求加载
      page: 1,
      pageSize: 10,
      radio: "",
      timeArr: [],
      isFirstTimeLoading: true, //是否为第一次加载
      downLoadStatus: false, //导出
      exportForm: null,
      currentTab: 0,
      historyTrackListLength: 0,
      alarmPositionDataLength: 0,
      stopsDataLength: 0,
      fileName: '',
    };
  },
  computed: {
    multipleTableHeight: function () {
      return this.bigTrailTable === "half"
        ? 262
        : this.bigTrailTable === "full"
          ? 469
          : 55; // pltable高度
    },
  },

  methods: {
    tableCellClassName({ row, column, rowIndex, columnIndex }){
      let num = (row.pkt).substring(0,row.pkt.indexOf("分"));
      if( Number(num) > 19 && columnIndex === 3){
        return 'color:#ff1212;'
      }
    },
    //指定行修改文字颜色
    rowColor ({ row, rowIndex }) {
      if (row.l === 1 || row.l === null) {
        return 'redColor'
      }
      return ''
    },
    //切换tab
    AlarmRefresh (index) {
      if (this.currentTab === index) return;
      this.currentTab = index;
      this.tableTotal = index === 0 ? this.historyTrackListLength : index === 1 ? this.alarmPositionDataLength : this.stopsDataLength
    },
    // 切换查询时间
    selectTime (value) {
      // this.changeTime(value);
    },
    // 获取时间段按钮数据
    getTimeArr () {
      this.timeArr = [];
      const beginTime = new Date(formatDay(this.paramData.beginTime)).getTime();
      const endTime = new Date(formatDay(this.paramData.endTime)).getTime();
      const count = (endTime - beginTime) / (24 * 60 * 60 * 1000);
      for (let i = 0; i <= count; i++) {
        this.timeArr.push(formatDay(new Date(beginTime + i * 24 * 60 * 60 * 1000)));
      }
      this.radio = this.timeArr[0];
    },
    initFirstTimeLoading () {
      this.radio = this.timeArr[0];
      this.isFirstTimeLoading = true;
    },
    /* 展示某个点的位置 */
    locatePoint (row) {
      this.$emit("locatePointShow", row);
    },
    /** 轨迹表格放大 */
    enlargeTable () {
      this.bigTrailTable = this.bigTrailTable === "half" ? "full" : "half";
      let bottom = document.getElementsByClassName("el-icon-bottom");
      let obj = document.getElementsByClassName("el-icon-top");
      for (let i = 0; i < bottom.length; i++) {
        bottom[i].style.display = "inline-block";
      }
      for (let i = 0; i < obj.length; i++) {
        obj[i].style.display = this.bigTrailTable === "full" ? "none" : "inline-block";
      }
    },
    /** 轨迹表格缩小 */
    closeTable () {
      this.bigTrailTable = this.bigTrailTable === "full" ? "half" : "none";
      let top = document.getElementsByClassName("el-icon-top");
      let obj = document.getElementsByClassName("el-icon-bottom");

      for (let i = 0; i < top.length; i++) {
        top[i].style.display = "inline-block";
      }
      for (let i = 0; i < obj.length; i++) {
        obj[i].style.display = this.bigTrailTable === "none" ? "none" : "inline-block";
      }
      if (this.bigTrailTable === "none") {
        this.showTab = false;
        this.TabHeight = "0vh";
        this.mapHeight = "90vh";
      }
    },
    /* 展示轨迹表格 */
    showHistoryTable (obj, resObj, isFirst, name) {
      this.fileName = name;//轨迹导出名格式调整
      delete obj.currentPage;
      this.paramData = { ...obj };
      this.resetTrailTable();
      if (this.$refs.timePage) this.$refs.timePage.initFirstTimeLoading();
      if (resObj.length === 0) {
        this.historyTrackListLength = 0;
        this.alarmPositionDataLength = 0;
        this.stopsDataLength = 0;
        this.tableTotal = this.historyTrackListLength;
        return
      }
      this.historyTrackListLength = resObj.historyTrackList?.length || 0;
      this.alarmPositionDataLength = resObj.alarmPositionData?.length || 0;
      this.stopsDataLength = resObj.stops?.length || 0;
      this.tableTotal = this.historyTrackListLength;//给总条数赋初始值
      this.trailTableDataBase = resObj.historyTrackList || [];
      this.trailTableData = [...this.trailTableDataBase.splice(0, 10)];
      this.alarmDataBase = resObj.alarmPositionData || [];
      this.alarmData = [...this.alarmDataBase.splice(0, 10)];
      //停靠点数据处理
      let stopArrSingle = resObj.stops || [];
      let arr = [];
      this.stopsDataBase = stopArrSingle;
      let tenArr = [...this.stopsDataBase.splice(0, 10)];//前十条数据
      for (let i = 0; i < tenArr.length; i++) {
        let s = new Promise((resolve, reject) => {
          oneGpsToLngLat(tenArr[i].lg, tenArr[i].lt, true).then((res) => {
            getPlace(res[0], res[1]).then((res) => {
              if (res) {
                tenArr[i].address = res
                resolve(tenArr)
              }
            })
          })
        })
        arr.push(s)
      }
      Promise.all(arr).then(res => {
        this.stopsData = [...tenArr]
      })
      if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
      if (this.$refs.multipleTable) this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
      if (this.$refs.multipleTableStop) this.$refs.multipleTableStop.doLayout();
      if (this.$refs.multipleTableStop) this.$refs.multipleTableStop.pagingScrollTopLeft(0, 0);
      this.exportForm = { ...obj };
      if (isFirst === false) this.isFirstTimeLoad = false;
    },
    /* 展示轨迹表格 */
    showHistoryTableOld (data) {
      // this.getTimeArr();
      delete data.startTime;
      delete data.currentPage;
      this.paramData = { ...data };
      this.resetTrailTable();
      if (this.$refs.timePage) this.$refs.timePage.initFirstTimeLoading();
      //获取表格总数
      positionCount(this.paramData).then((res) => {
        if (res.code === 1000) {
          this.tableTotal = res.data;
        }
      });
      const timeData = {
        beginTime: data.beginTime,
        endTime: data.endTime
      }
      let trackArr = []
      let alarmArr = []
      gethistoryTrackNew(this.paramData).then((res) => {
        if (res.code === 1000) {
          this.trailTableData = res.data.historyTrackList || [];
          this.alarmData = res.data.alarmPositionData || [];

          if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
          if (this.$refs.multipleTable) {
            this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
          }
        } else {
          this.trailTableData = [];
        }
        this.exportForm = { ...data };
        this.isFirstTimeLoad = false;
      });
      return
      getQueryTimeSection(timeData).then(res => {
        if (res.code === 1000) {
          /**
           * 请求轨迹查询日志-记录查询日志
           */
          // this.timeSectionArr = res.data;
          //得到时间数组，循环每一项请求列表，push进轨迹回放table中
          let arr = res.data.map(item => {
            return this.requestAll({
              system: data.system,
              beginTime: item.beginDate,
              endTime: item.endDate,
              vehId: data.vehId,
              deviceType: data.deviceType
            });
          })
          Promise.all(arr).then(result => {
            console.log(result)
            // const rl = JSON.parse(result)
            // console.log(10000,rl)
            result.map(item => {
              if (item.historyTrackList) trackArr.push(...item.historyTrackList)
              if (item.alarmPositionData) alarmArr.push(...item.alarmPositionData)
            })
            // this.trailTableDataBase = trackArr;
            // this.trailTableData = [...this.trailTableDataBase.splice(0, 20)];
            this.trailTableData = trackArr;
            this.alarmData = alarmArr;
            //数据量太大，加一个提示，提高用户体验
            this.exportForm = { ...data };
            if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
            if (this.$refs.multipleTable) {
              this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
            }
          }).catch(error => {
            this.trailTableData = []
            console.log(error)
          })
        }
      }).catch(err => {
        console.log(err)
      })
      //获取表格数据详情
      // getHistoryTrack(this.paramData).then((res) => {
      //   if (res.code === 1000 && res.data.length) {
      //     // 将请求到的数据放入数据仓库中
      //     this.trailTableDataBase = res.data;
      //     this.queryEndTime = res.data[res.data.length - 1].time;
      //     // 从数据仓库中取10条数据放入轨迹回放表格中
      //     this.trailTableData = [...this.trailTableDataBase.splice(0, 10)];
      //     // this.showTrailReplayForm()
      //     if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout();
      //     if (this.$refs.multipleTable) {
      //       this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
      //     }
      //   } else {
      //     this.trailTableData = [];
      //   }
      //   this.exportForm = { ...data };
      //   this.isFirstTimeLoad = false;
      // });
    },
    /**promise.all */
    requestAll (val) {
      return new Promise((resolve, reject) => {
        gethistoryTrackNew(val).then(res => {
          resolve(res.data)
        })
      })
    },
    /** 加载轨迹表格 */
    load () {
      this.$nextTick(() => {
        this.append();
        this.appendMore();
        this.appendStop()
      });
    },

    /* 每次滚动加载从数据仓库中取出10条数据 */
    append () {
      if (this.trailTableDataBase.length === 0) return;
      this.trailTableData = [
        ...this.trailTableData,
        ...this.trailTableDataBase.splice(0, 10),
        //改成再加载一次，把数据仓库里面的数据全部拿出来
      ];
    },
    appendMore () {
      if (this.alarmDataBase.length === 0) return;
      this.alarmData = [
        ...this.alarmData,
        ...this.alarmDataBase.splice(0, 10),
      ];
    },
    /**加载停靠点表格 */
    appendStop () {
      if (this.stopsDataBase.length === 0) return;
      let arr = []
      let tenArr = [...this.stopsDataBase.splice(0, 10)];//前十条数据
      for (let i = 0; i < tenArr.length; i++) {
        let s = new Promise((resolve, reject) => {
          oneGpsToLngLat(tenArr[i].lg, tenArr[i].lt, true).then((res) => {
            getPlace(res[0], res[1]).then((res) => {
              if (res) {
                tenArr[i].address = res
                resolve(tenArr)
              }
            })
          })
        })
        arr.push(s)
      }
      Promise.all(arr).then(res => {
        this.stopsData = [
          ...this.stopsData,
          ...tenArr,
        ];
      })

    },
    // 切换查询时间
    changeTime (val) {
      this.changeTimeOver = false;
      let date = `${val} 00:00:00`;
      if (this.paramData.beginTime.split(" ")[0] === val) {
        date = this.paramData.beginTime;
      }
      this.queryStartTime = date;
      // 重置查询轨迹回放报表
      this.trailTableData = [];
      this.trailTableDataBase = [];
      this.isQueryEnd = false;
      this.isLoadOver = false;
      let deviceType = this.system === "2" ? "0" : this.system

      // const queryData = {
      //   system: this.system,
      //   pageSize: 100,
      //   vehId: this.paramData.vehId,
      //   pageTime: date,
      //   beginTime: this.paramData.beginTime,
      //   endTime: this.paramData.endTime,
      //   deviceType
      // };

      // 查询轨迹回放报表
      // getHistoryTrack(queryData).then((res) => {
      //   if (res.code === 1000) {
      //     // 将请求到的数据放入数据仓库中
      //     this.trailTableDataBase = res.data;
      //     // 从数据仓库中取10条数据放入轨迹回放表格中
      //     this.changeTimeOver = true;
      //     this.trailTableData = [...this.trailTableDataBase.splice(0, 10)];

      //     this.$refs.multipleTable.doLayout();
      //     this.$refs.multipleTable.pagingScrollTopLeft(0, 0);
      //   }
      //   this.exportForm = { ...queryData };
      // });
    },
    /** 导出轨迹 */
    epxortTrail (val) {
      //弹出确认是否解析地址弹窗
      let objParam = Object.assign(this.exportForm, { resolveAddress: val })
      let data = {
        baseUrl: "base",
        fileName: this.fileName,
        // fileName: `轨迹信息-${getCurent()}`,
        queryParam: JSON.stringify(objParam),
        queryPath: "/monitor/positionExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    //停靠点导出
    epxortTrailStops () {
      let data = {
        baseUrl: "base",
        fileName: `停车点信息-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/monitor/positionStopsExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    resetTrailTable () {
      this.bigTrailTable = "half";
      this.isFirstTimeLoad = true;
      this.isQueryEnd = false;
      this.isLoadOver = false;
      this.trailTableData = [];
      this.trailTableDataBase = [];
      this.alarmDataBase = [];
      this.tableTotal = 0;

      if (this.$refs.pagination) this.$refs.pagination.reset();
    },
    /** 显示位置信息 */
    showAdress (row, column, index) {
      if (column.label == "位置") {
        if (!row.longitude) {
          this.$message.warning("暂无经纬度数据")
          return
        }
        getPlace(row.longitude, row.latitude).then((res) => {
          if (res) {
            row.address1 = res
            // res.province + res.city + res.district + res.street + res.township + res.streetNumber
            this.$set(this.trailTableData, index, row)
          }
        })
      }
    },
  },
  created () { },
  mounted () { },
  watch: {
    trailTableDataBase (val) {
      if (this.isLoadOver) return;
      if (!this.isQueryEnd && !this.isFirstTimeLoad && this.changeTimeOver) {
        // 轨迹回放查询结束时间为上次查询结果最后一条的时间
        if (val.length !== 0) {
          this.queryEndTime = val[val.length - 1].time;
        } else {
          this.queryEndTime = this.trailTableData[this.trailTableData.length - 1].time;
        }
        // 如果轨迹回放表格数据仓库存储的数据小余100条就重新请求100条数据
        if (val.length < 100) {
          this.isLoadOver = true;
          let deviceType = this.system === "2" ? "0" : this.system

          // let data = {
          //   system: this.system,
          //   pageSize: 100,
          //   vehId: this.paramData.vehId,
          //   pageTime: this.queryEndTime,
          //   beginTime: this.paramData.beginTime,
          //   endTime: this.paramData.endTime,
          //   deviceType
          // };
          // getHistoryTrack(data).then((res) => {
          //   if (res.code === 1000 && res.data.length) {
          //     // 如果查询结果小余100,则以后将不再请求数据
          //     if (res.data.length < 100) {
          //       this.isQueryEnd = true;
          //     }
          //     // 记录最后一条请求数据的时间
          //     this.queryEndTime = res.data[res.data.length - 1].time;
          //     /** young 后台处理 20200426 */
          //     // res.data = res.data.splice(1);
          //     this.isLoadOver = false;
          //     // 将请求到的数据添加到仓库中
          //     this.trailTableDataBase = [...this.trailTableDataBase, ...res.data];
          //   }
          //   this.exportForm = { ...data };
          // });
        }
      }
    },
    "paramData.beginTime" (val) {
      this.getTimeArr();
    },
    "paramData.endTime" (val) {
      this.getTimeArr();
    },
    timeArr (val) {
      if (!this.isFirstTimeLoading) {
        this.$emit("selectTime", this.timeArr[0]);
      }
      this.isFirstTimeLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pl-table {
  background: #ffffff;
  @include themify() {
    width: 100%;
    position: absolute !important;
    bottom: 0;
    z-index: 160;
    .head-icon {
      color: themed('b4');
    }
    .pagination {
      height: 30px;
      background: transparent;
      position: fixed;
      bottom: 10px;
      right: 230px;
      z-index: 10;
    }
    .top-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0;
    }
  }
}
/deep/.redColor td {
  color: #ff1212 !important;
}
</style>
