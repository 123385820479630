<template>
  <div class="demo-form-inline dialog-form ctm">
    <p>
      <strong>指令内容</strong>
    </p>
    <div class="instruction">
      <div class="ParametersBox">
        <p class="parameters">
          <span>车牌号 ：</span>
          <span class="parametersVal">{{ parameter.sVehNo }}</span>
        </p>
        <p class="parameters">
          <span>终端号 ：</span>
          <span class="parametersVal">{{ parameter.terminalNo }}</span>
        </p>
        <p class="parameters">
          <span>指令 ：</span>
          <span class="parametersVal">{{ parameter.sCmdTxt }}</span>
        </p>
      </div>
      <p class="title">指令参数</p>
      <div class="ParametersBox" v-if="parameter.param.length > 0">
        <p v-for="(item, i) in parameter.param" :key="i" class="parameters">
          <span>{{ item }}</span>
        </p>
      </div>
      <p v-else>无</p>
      <p>下发时间 ： {{ parameter.sTime }}</p>
    </div>

    <p>
      <strong>返回内容</strong>
    </p>
    <div class="instruction">
      <p class="title">返回参数</p>
      <p>返回时间 : {{ parameter.receiveTime }}</p>
      <!-- 查询终端属性-->
      <div
        class="ParametersBox attr"
        v-if="parameter.sNote.length > 0 && typeof parameter.sNote === 'object'"
      >
        <p v-for="(item, i) in parameter.sNote" :key="i" class="parameters">
          <span>{{ item.name }}</span>
          <span :title="item.value" class="parametersVal">
            {{ item.value }}
          </span>
        </p>
      </div>
      <!-- 查询终端参数 -->
      <div
        v-else-if="parameter.sNote.alarm && typeof parameter.sNote === 'object'"
      >
        <p class="title">门限配置</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.alarm"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
          <p class="parameters">
            <span>夜间限速比例(%) ：</span>
            <span :title="other.nightSpeedLimitPer" class="parametersVal">
              {{ other.nightSpeedLimitPer }}
            </span>
          </p>
          <p class="parameters">
            <span>白天时间段 ：</span>
            <span :title="other.period" class="parametersVal">
              {{ other.period }}
            </span>
          </p>
        </div>
        <p class="title">网络参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.net"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <!-- gps的屏蔽报警，出租车的不同 -->
        <p class="title" v-if="system !== '1'">屏蔽报警</p>
        <div class="ParametersBox" v-if="system !== '1'">
          <p
            v-for="(item, i) in parameter.sNote.shieldingAlarm"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>

        <p class="title" v-if="system !== '1'">关闭报警声音</p>
        <div class="ParametersBox" v-if="system !== '1'">
          <p
            v-for="(item, i) in parameter.sNote.closeAlarmVoice"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <!-- gps的屏蔽报警，出租车的不同 -->
        <!-- 2022过检新增参数 -->
        <p class="title">音视频参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.audioAndVideo"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <!-- 高级参数adas -->
        <p class="title">驾驶员辅助功能参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.adas"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <!-- 高级参数dsm -->
        <p class="title">驾驶员行为监测功能参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.dsm"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <p class="title" v-if="parameter.sNote.tire.length > 0">
          轮胎状态监测参数设置
        </p>
        <div class="ParametersBox" v-if="parameter.sNote.tire.length > 0">
          <p
            v-for="(item, i) in parameter.sNote.tire"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <p class="title" v-if="parameter.sNote.blind.length > 0">
          盲区监测参数设置
        </p>
        <div class="ParametersBox" v-if="parameter.sNote.blind.length > 0">
          <p
            v-for="(item, i) in parameter.sNote.blind"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <p class="title" v-if="parameter.sNote.fierce.length > 0">
          激烈驾驶报警配置
        </p>
        <div class="ParametersBox" v-if="parameter.sNote.fierce.length > 0">
          <p
            v-for="(item, i) in parameter.sNote.fierce"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <p class="title" v-if="parameter.sNote.limitHeight.length > 0">
          载重限高参数
        </p>
        <div
          class="ParametersBox"
          v-if="parameter.sNote.limitHeight.length > 0"
        >
          <p
            v-for="(item, i) in parameter.sNote.limitHeight"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <p class="title" v-if="parameter.sNote.night.length > 0">
          夜间限速参数
        </p>
        <div class="ParametersBox" v-if="parameter.sNote.night.length > 0">
          <p
            v-for="(item, i) in parameter.sNote.night"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
        <!-- 2022过检新增参数 -->
        <p class="title">其他参数</p>
        <div class="ParametersBox">
          <p
            v-for="(item, i) in parameter.sNote.other"
            :key="i"
            class="parameters"
          >
            <span>{{ item.name }} ：</span>
            <span :title="item.value" class="parametersVal">
              {{ item.value }}
            </span>
          </p>
        </div>
      </div>
      <!-- 实时拍照 -->
      <div
        v-else-if="
          parameterObj.sCmdTxt === '实时拍照' && parameter.sNote.length > 0
        "
        class="real-img"
      >
        <div v-for="(item, index) in imgurl" :key="index">
          <img :src="item.url" alt />
        </div>
      </div>
      <p v-else>{{ parameter.sNote }}</p>
      <!-- 查询车辆行驶记录-采集指定的行驶速度记录 -->
      <div
        v-if="
          parameterObj.sCmdTxt === '查询车辆行驶记录' &&
            parameter.drivingRecords &&
            parameter.drivingRecords.length > 0
        "
        class="drivingRecords-box"
      >
        <p>详情 : <el-button @click="exportFun">导出</el-button></p>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { queryDeviceParams } from "@/api/lib/api";
import { querySnapshotImgs } from "@/api/lib/refreshAlarm.js";
export default {
  props: {
    parameterObj: {
      type: Object,
      default: function(params) {
        return {
          sCmdTxt: "",
          param: [],
          sNote: []
        };
      }
    }
  },
  computed: {
    /** table的最大高度 */
    maxTabHeight() {
      return `${this.height - 50}px`;
    }
  },
  data() {
    return {
      parameter: {
        sCmdTxt: "",
        param: [],
        sNote: []
      },
      other: {
        nightSpeedLimitPer: null,
        period: null
      },
      imgurl: [],
      timer: null,
      system: sessionStorage.getItem("system")
    };
  },
  methods: {
    exportFun() {
      console.log(this.parameter);
      let exportText = this.parameter.param[0].substring(3),
        headers = null,
        data = [],
        drivingRecords = this.parameter.drivingRecords.toReversed();
      switch (exportText) {
        case "采集指定的行驶速度记录":
          headers = ["时间", "速度"];
          drivingRecords.forEach(element => {
            let beginTime = new Date(element.beginTime).valueOf();
            element.speedRecord.forEach((item, index) => {
              data.unshift([
                moment(new Date(beginTime + 1000 * index)).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                item.speed
              ]);
            });
          });
          break;
        case "采集指定的位置信息记录":
          headers = ["时间", "平均速度", "经度", "纬度", "海拔"];
          drivingRecords.forEach(element => {
            let beginTime = new Date(element.beginTime).valueOf();
            element.positionRecord.forEach((item, index) => {
              data.unshift([
                moment(new Date(beginTime + 1000 * 60 * index)).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                item.speed,
                item.longitude,
                item.latitude,
                item.altitude
              ]);
            });
          });
          break;
        case "采集指定的事故疑点记录":
          headers = ["时间", "机动车驾驶证号码", "经度", "纬度", "海拔"];
          drivingRecords.forEach(element => {
            data.unshift([
              element.endTime,
              element.driverCardNo,
              element.longitude,
              element.latitude,
              element.altitude
            ]);
          });
          break;
        case "采集指定的驾驶人身份记录":
          headers = ["发生时间", "机动车驾驶证号码", "事件类型"];
          drivingRecords.forEach(element => {
            data.unshift([
              element.realTime,
              element.driverCardNo,
              element.typeStr
            ]);
          });
          break;
        case "采集指定的外部供电记录":
          headers = ["发生时间", "事件类型"];
          drivingRecords.forEach(element => {
            data.unshift([element.realTime, element.typeStr]);
          });
          break;
        case "采集指定的速度状态日志":
          headers = [
            "开始时间",
            "结束时间",
            "速度状态",
            "记录速度",
            "参考速度"
          ];
          drivingRecords.forEach(element => {
            let beginTime = new Date(element.beginTime).valueOf();
            element.speedRecord.forEach((item, index) => {
              data.unshift([
                moment(new Date(beginTime + 1000 * index)).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                element.endTime,
                element.statusStr,
                item.recordSpeed,
                item.referenceSpeed
              ]);
            });
          });
          break;
        default:
          break;
      }
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `查询车辆行驶记录-${exportText}${moment(new Date()).format(
          "YYYY-MM-DD HH:mm:ss"
        )}.xlsx`
      );
    },
    queryBase64Img() {
      let arr = [];
      const data = {
        terminalPhone: this.parameterObj.terminalNo,
        mediaIDs: this.parameterObj.sNote.split(",")
      };
      querySnapshotImgs(data).then(res => {
        if (res.code === 1000) {
          const d = res.data || [];
          d.map(item => {
            arr.push({
              url: `data:image/png;base64,${item}`
            });
          });
          this.imgurl = [...arr];
        }
      });
    }
  },
  mounted() {
    this.parameter = { ...this.parameter, ...this.parameterObj };
    if (this.parameter.sCmdTxt.indexOf("查询终端参数") > -1) {
      //处理转义符
      if (this.parameter.sNote && this.parameter.sNote instanceof Array) {
        this.parameter.sNote.map(item => {
          if (item.value && typeof item.value == "string") {
            item.value = item.value.replace("\u0000", "");
          }
        });
      }
      //处理转义符结束
      queryDeviceParams(this.parameter.terminalNo).then(res => {
        if (res.code === 1000) {
          let period = null;
          if (res.data && res.data.period) {
            let start = res.data.period.split("_")[0].split(" ")[1];
            let end = res.data.period.split("_")[1].split(" ")[1];
            period =
              start.substring(0, start.length - 3) +
              "-" +
              end.substring(0, end.length - 3);
            this.other.nightSpeedLimitPer = res.data.nightSpeedLimitPer;
            this.other.period = period;
          }
        }
      });
    }
    if (this.parameter.sCmdTxt.indexOf("实时拍照") > -1) {
      //轮询请求图片接口
      this.queryBase64Img();
      this.timer = setInterval(() => {
        this.queryBase64Img();
      }, 3 * 1000);
    }
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="scss" scoped>
.ctm {
  height: 55vh;
  overflow-y: auto;
}
p.title {
  height: 36px;
  font-weight: 600;
}
.instruction {
  padding: 10px;
  p {
    height: 36px;
    display: flex;
  }
  .ParametersBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    p.parameters {
      width: calc(33%);
      .parametersVal {
        font-size: 14px;
        letter-spacing: -0.34px;
        text-align: right;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .real-img {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    div {
      margin: 0 20px 20px;
    }
    img {
      width: 352px;
      height: 288px;
    }
  }
  .drivingRecords-box {
    p {
      display: flex;
      align-items: center;
    }
    .el-button {
      margin-left: 34px;
    }
  }
  .attr {
    // p.parameters {
    //   width: calc(90% - 30px);
    //   overflow: hidden;
    //   display: inline-block;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
  }
}
</style>
