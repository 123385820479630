<template>
  <div class="form-area">
    <el-form
      :rules="rules"
      :inline="true"
      ref="form"
      :model="form"
      class="demo-form-inline"
    >
      <!-- 开始时间： -->
      <el-form-item
        :prop="timeLimit == 0 ? '' : 'startTime'"
        :label="dateName ? dateName : '开始时间：'"
      >
        <el-date-picker
          v-model="form.startTime"
          :type="dateType"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 结束时间： -->
      <el-form-item :prop="timeLimit == 0 ? '' : 'endTime'" label="结束时间：">
        <el-date-picker
          v-model="form.endTime"
          :type="dateType"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="selectCar"
        :prop="needChoseCar ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        v-if="selectCar"
        :prop="needChoseCar ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="serch">查询</el-button>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 备注：此组件的选择车辆分为：
 *  vehIds: null,
 *   companyIds: null,
 */
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import {
  getStartDate,
  getCurentDate,
  formatDate,
  checkTimeLimit,
  checkTimeEqualLimit,
  checkTimeLimitDay,
  formatDay,
  checkLimitDate
} from '@/common/utils/index'
export default {
  props: {
    /** 是否显示选择车辆 */
    selectCar: {
      type: Boolean,
      default: true
    },
    dateName: {
      type: String
    },

    timeLimit: {
      type: Number,
      default: 31
    },
    dateType: {
      type: String,
      default: 'datetime'
    },

    /* 已选中的车辆 */
    chooseCar: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    /** 20190116 没有改的报表需要车辆必选 */
    needChoseCar: {
      type: Boolean,
      default: true
    }
  },
  components: {
    carTree,
    companySelectForSearch
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }
    const validateDate1 = (rule, value, callback) => {
      if (
        !checkTimeLimit(value, this.form.endTime) &&
        this.dateType === 'datetime'
      ) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (
        !checkTimeEqualLimit(value, this.form.endTime) &&
        this.dateType === 'date'
      ) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (!checkTimeLimitDay(value, this.form.endTime, this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`))
      } else {
        callback()
      }
    }
    const validateDate2 = (rule, value, callback) => {
      if (
        !checkTimeLimit(this.form.startTime, value) &&
        this.dateType === 'datetime'
      ) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (
        !checkTimeEqualLimit(this.form.startTime, value) &&
        this.dateType === 'date'
      ) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (!checkTimeLimitDay(this.form.startTime, value, this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`))
      } else {
        callback()
      }
    }

    return {
      form: {
        startTime: new Date(getStartDate()),
        endTime: new Date(getCurentDate()),
        companyIds: [],
        day: 1,
        vehIds: []
      },
      isId: false,
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const now = new Date()
              const today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const now = new Date()
              const oneDay = 1000 * 60 * 60 * 24
              const today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              const date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const now = new Date()
              const oneDay = 1000 * 60 * 60 * 24
              const today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              const date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        startTime: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          },
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ],
        endTime: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          },
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate2, trigger: 'change' }
        ],
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehIds = [...val]
    },
    /** 点击查询，传值到父组件 */
    serch () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {}
          if (this.dateType === 'datetime') {
            data = {
              ...this.form,
              startTime: formatDate(this.form.startTime),
              endTime: formatDate(this.form.endTime)
            }
          } else {
            data = {
              ...this.form,
              startTime: formatDay(this.form.startTime),
              endTime: formatDay(this.form.endTime)
            }
          }
          this.$emit('getFormVal', data)
        } else {
          return false
        }
      })
    },
    /** 重置表单 */
    resetForm () {
      this.form = {
        startTime: new Date(getStartDate()),
        endTime: new Date(getCurentDate()),
        companyIds: [],
        vehIds: []
      }
    }
  }
}
</script>
