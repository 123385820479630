<template>
  <div class="video-form">
    <el-form :rules="rules" ref="form" :model="form" class="self-form">
      <!-- 开始时间： -->
      <el-form-item prop="day" label="日期范围：" label-width="92px">
        <el-date-picker
          v-model="form.day"
          align="right"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="time">
        <el-time-picker
          is-range
          v-model="form.time"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
        ></el-time-picker>
      </el-form-item>

      <el-form-item label=" " label-width="12px">
        <el-button type="primary" size="small" @click="serch">查询</el-button>
        <el-button size="small" @click="showMore" class="more-btn">
          {{moreStatus?'收起':'更多'}}
          <i class="iconfont" :class="moreStatus?'its_caret-up':'its_caret-down'"></i>
        </el-button>
      </el-form-item>
    </el-form>
    <div class="more-main" v-if="moreStatus">
      <el-form ref="form2" :model="form2" label-width="92px" class="form-bottom">
        <el-form-item label="存储类型：">
          <el-radio-group v-model="form2.address">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">主存储器</el-radio>
            <el-radio :label="2">灾备存储器</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="报警类型：">
          <el-radio-group v-model="form2.alarmType">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">状态位报警类型</el-radio>
          </el-radio-group>
          <el-select v-model="form2.options" style="margin-left:20px;" placeholder="请选择" multiple clearable collapse-tags v-if="form2.alarmType===1">
            <el-option
              v-for="item in alarmOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="码流类型：">
          <el-radio-group v-model="form2.streamType">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1">主码流</el-radio>
            <el-radio :label="2">子码流</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="音视频资源类型：" label-width="134px">
          <el-radio-group v-model="form2.videoType">
            <el-radio :label="0">音视频</el-radio>
            <el-radio :label="1">音频</el-radio>
            <el-radio :label="2">视频</el-radio>
            <el-radio :label="3">音频或视频</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getStartDate,
  getCurentDate,
  formatDate,
  checkTimeLimit,
  checkLimitDate,
  formatDay
} from '@/common/utils/index'

export default {
  data () {
    let validateDate = (rule, value, callback) => {
      if (value && value !== '') {
        let day = formatDay(new Date())
        let startTime = `${day} ${formatDate(value[0]).split(' ')[1]}`
        let endTime = `${day} ${formatDate(value[1]).split(' ')[1]}`
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`))
        } else {
          callback()
        }
      } else {
        callback(new Error(`请选择`))
      }
    }

    return {
      timeDivance: 4, // 开始时间比当前时间提前4小时
      form: {
        time: null,
        day: new Date()
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              let now = new Date()
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        day: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        time: [{ validator: validateDate, trigger: 'change' }]
      },
      moreStatus: false,
      form2: {
        address: 0,//存储类型
        alarmType: 0,//报警类型
        streamType: 0,//码流类型
        videoType: 3,//音视频资源类型
        options:''//报警类型的值
      },
      alarmOptions: [
        { value: '紧急报警', label: '紧急报警' },
        { value: '超速报警', label: '超速报警' },
        { value: '疲累报警', label: '疲累报警' },
        { value: '预警', label: '预警' },
        { value: 'GNSS故障报警', label: 'GNSS故障报警' },
        { value: 'GNSS天线开路报警', label: 'GNSS天线开路报警' },
        { value: 'GNSS天线短路报警', label: 'GNSS天线短路报警' },
        { value: '终端主电源欠压报警', label: '终端主电源欠压报警' },
        { value: '终端主电源掉电报警', label: '终端主电源掉电报警' },
        { value: '终端LCD故障报警', label: '终端LCD故障报警' },
        { value: 'TTS模块故障报警', label: 'TTS模块故障报警' },
        { value: '摄像头故障报警', label: '摄像头故障报警' },
        { value: '道路运输证IC卡模块故障', label: '道路运输证IC卡模块故障' },
        { value: '超速预警', label: '超速预警' },
        { value: '疲劳驾驶预警', label: '疲劳驾驶预警' },
        { value: '当天累计驾驶超时报警', label: '当天累计驾驶超时报警' },
        { value: '停车超时报警', label: '停车超时报警' },
        { value: '进出区域报警', label: '进出区域报警' },
        { value: '进出路线报警', label: '进出路线报警' },
        { value: '路段行驶时间不足', label: '路段行驶时间不足' },
        { value: '路线偏离报警', label: '路线偏离报警' },
        { value: '车辆VSS故障报警', label: '车辆VSS故障报警' },
        { value: '车辆油量异常报警', label: '车辆油量异常报警' },
        { value: '盗警', label: '盗警' },
        { value: '非法点火报警', label: '非法点火报警' },
        { value: '非法移位报警', label: '非法移位报警' },
        { value: '碰撞侧翻报警', label: '碰撞侧翻报警' },
        { value: '侧翻预警', label: '侧翻预警' },
      ]
    }
  },
  methods: {
    showMore () {
      this.moreStatus = !this.moreStatus
    },
    /** 点击查询，传值到父组件 */
    serch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            startTime: `${formatDay(this.form.day)} ${
              formatDate(this.form.time[0]).split(' ')[1]
              }`,
            endTime: `${formatDay(this.form.day)} ${
              formatDate(this.form.time[1]).split(' ')[1]
              }`
          }
          let endData = {
            ...data,
            address:this.form2.address,
            alarmType:this.form2.alarmType===0?'':this.form2.options.join('|'),
            streamType:this.form2.streamType,
            videoType:this.form2.videoType
          }
          this.$emit('getFormVal', endData)
        } else {
          return false
        }
      })
    },
    /** 重置表单 */
    resetForm () {
      if (this.selectCar) this.$refs.selectCarDom.clear()
      this.form.time = [new Date(getStartDate()), new Date(getCurentDate())]
    }
  },
  created () {
    let starTime =
      new Date(getCurentDate()).valueOf() - this.timeDivance * 60 * 60 * 1000
    let beginTime =
      new Date(getStartDate()) < new Date(starTime)
        ? new Date(starTime)
        : new Date(getStartDate())
    this.form.time = [beginTime, new Date(getCurentDate())]
  }
}
</script>
<style lang="scss" scoped>
.video-form {
  box-shadow: 0px 2px 4px 0px rgba(52, 52, 52, 0.2);
  /deep/.el-form .el-form-item .el-form-item__content{
    display: flex;
  }
  .form-bottom {
    display: block;
    .el-radio-group {
      margin-top: 8px;
      display: flex;
      align-items: center;
    }
  }
}
.self-form {
  display: flex;
  align-items: center;
}
.more-btn {
  padding: 0 14px;
  line-height: 32px;
  .iconfont {
    margin-top: 2px;
  }
}
</style>
