import service from './requestHistoryTrack'
//接口前缀
const BASE_PREFIX = '/base';
//轨迹回放列表数据查询新(谢2021.7.29)
export const gethistoryTrackNew = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrackNew`,
    method: 'get',
    data
  })
}
