var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "100%", height: "100%" },
      attrs: { id: "VidedList" }
    },
    _vm._l(_vm.maxVideoNum, function(value, key) {
      return _c(
        "div",
        {
          key: key,
          staticClass: "videoDiv",
          staticStyle: { overflow: "hidden", float: "left" },
          style: {
            width: _vm.getSize(key) + "%",
            height: _vm.getSize(key) + "%"
          },
          attrs: { id: "VidedList" + (key + 1) },
          on: {
            dbclick: function($event) {
              return _vm.cblVideoQPClick(key + 1)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "video", attrs: { id: "videoP" + (key + 1) } },
            [
              _c("div", {
                staticClass: "videoObj cover",
                attrs: { id: "video" + (key + 1) }
              }),
              _c(
                "div",
                {
                  class: {
                    selected: _vm.selectedId == key + 1,
                    videoScreen: !value[key + 1]
                  },
                  attrs: { id: "videoScreen" + (key + 1) },
                  on: { click: _vm.videoScreenClick }
                },
                [
                  _c("span", { staticClass: "carVideoName" }),
                  value[key + 1]
                    ? _c("div", {
                        staticClass: "videoPlayBtn",
                        class: "playImg" + (key + 1),
                        attrs: { id: "videoPlayBtn" + (key + 1) }
                      })
                    : _vm._e(),
                  !value.isOpen || !value.isMuted
                    ? _c("div", { staticClass: "videoInfo errInfo" })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: value[key + 1] && !_vm.isHistory,
                          expression: "value[key + 1] && !isHistory"
                        }
                      ],
                      staticClass: "videoCtrlBottom"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "progress",
                          style: {
                            width:
                              (_vm.getVideoWidthHeight(key + 1).width / 3) * 2 +
                              "px"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "carPlate",
                              class:
                                _vm.maxVideoNum.length >= 6 ? "text-small" : "",
                              attrs: { title: value.plate }
                            },
                            [
                              _vm._v(
                                _vm._s(value.plate) +
                                  " " +
                                  _vm._s(parseInt(value.kBps)) +
                                  " kB/s"
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "rightctr",
                          style: {
                            width:
                              _vm.getVideoWidthHeight(key + 1).width / 3 + "px"
                          }
                        },
                        [
                          _c("a", {
                            class: value.isMuted
                              ? "mutedVideo"
                              : "isMutedVideo",
                            on: {
                              click: function($event) {
                                return _vm.mutedVideoClick(key + 1)
                              }
                            }
                          }),
                          _c("a", {
                            staticClass: "closecarVideo",
                            on: {
                              click: function($event) {
                                return _vm.flv_destroy(key + 1, true)
                              }
                            }
                          }),
                          _c("a", {
                            staticClass: "captureVideo",
                            on: {
                              click: function($event) {
                                return _vm.flv_screenshot(key + 1)
                              }
                            }
                          }),
                          _c("a", {
                            staticClass: "quanpcarVideo",
                            on: {
                              click: function($event) {
                                return _vm.quanpVideoClick(key + 1)
                              }
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm.showVideoControl &&
                  value[key + 1] &&
                  _vm.historyVideo.currentTime > 0
                    ? _c(
                        "div",
                        { staticClass: "videoControl" },
                        [
                          _c("el-slider", {
                            staticClass: "commonSlider",
                            attrs: {
                              "show-tooltip": false,
                              max: _vm.formateTimeRound(_vm.timeRound, true)
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeVideoTime($event, key + 1)
                              }
                            },
                            model: {
                              value: _vm.historyVideo.currentTime,
                              callback: function($$v) {
                                _vm.$set(_vm.historyVideo, "currentTime", $$v)
                              },
                              expression: "historyVideo.currentTime"
                            }
                          }),
                          _c("div", { staticClass: "progress" }, [
                            _c("div", { staticClass: "time" }, [
                              _c("i", {
                                class: value.isOpen
                                  ? "el-icon-video-pause"
                                  : "el-icon-video-play",
                                on: {
                                  click: function($event) {
                                    return _vm.pauseVideoClick(key + 1)
                                  }
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatTimeBySeconds(
                                      _vm.historyVideo.currentTime
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" / "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formateTimeRound(_vm.timeRound))
                                )
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    _vm._s(parseInt(value.kBps)) + " kB/s "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "rightctr" }, [
                              _c("a", {
                                class: value.isMuted
                                  ? "mutedVideo"
                                  : "isMutedVideo",
                                on: {
                                  click: function($event) {
                                    return _vm.mutedVideoClick(key + 1)
                                  }
                                }
                              }),
                              _c("a", {
                                staticClass: "recordVideo",
                                on: {
                                  click: function($event) {
                                    return _vm.jess_record(key + 1)
                                  }
                                }
                              }),
                              _c("a", {
                                staticClass: "captureVideo",
                                on: {
                                  click: function($event) {
                                    return _vm.flv_screenshot(key + 1)
                                  }
                                }
                              }),
                              _c("a", {
                                staticClass: "quanpcarVideo",
                                on: {
                                  click: function($event) {
                                    return _vm.quanpVideoClick(key + 1)
                                  }
                                }
                              })
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }