<template>
  <el-form :rules="rules" ref="form" :model="form" label-width="100px">
    <el-form-item label="时间范围：">
      <span class="el-range-input">{{ timeChoosed }}</span>
    </el-form-item>
    <!-- 选择时间范围 -->
    <el-form-item label="下载时间：" required>
      <el-col :span="11">
        <el-form-item prop="startTime">
          <el-time-picker
            placeholder="选择开始时间"
            v-model="form.startTime"
            style="width: 100%"
            :picker-options="pickerOptions"
          ></el-time-picker>
        </el-form-item>
      </el-col>
      <el-col class="el-range-input" :span="2" style="text-align: center"
        >-</el-col
      >
      <el-col :span="11">
        <el-form-item prop="endTime">
          <el-time-picker
            placeholder="选择结束时间"
            v-model="form.endTime"
            style="width: 100%"
            :picker-options="endpickerOptions"
          ></el-time-picker>
        </el-form-item>
      </el-col>
      <el-col>
        <span>最多选择{{ timeDiff }}分钟,时长越长,等待时间越久,下载成功率越小</span>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="serch">下载</el-button>
    </el-form-item>
    <slot></slot>
  </el-form>
</template>

<script>
import { formatDate, checkTimeLimit } from '@/common/utils/index'
export default {
  props: {
    params: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {},
  computed: {
    timeChoosed: function () {
      return `${this.currentPageParams.startTime} - ${this.currentPageParams.endTime}` // 已选时间
    }
  },
  created() {
    if (this.params) {
      this.currentPageParams = {...this.params}
      if(sessionStorage.getItem("system")==1){
        //转换时间
        this.currentPageParams.startTime = formatDate(this.currentPageParams.startTime)
        this.currentPageParams.endTime = formatDate(this.currentPageParams.endTime)
      }
      // console.log(1111,this.currentPageParams)
      // this.day = this.currentPageParams.startTime.split(' ')[0]
      this.startDay = this.currentPageParams.startTime.split(' ')[0]
      this.endDay = this.currentPageParams.endTime.split(' ')[0]
      let startTime = this.currentPageParams.startTime.split(' ')[1]
      let endTime = this.currentPageParams.endTime.split(' ')[1]
      this.form.startTime = new Date(this.currentPageParams.startTime)
      this.form.endTime = new Date(this.currentPageParams.endTime)
      this.pickerOptions.selectableRange = `${startTime} - ${endTime}`
      this.endpickerOptions.selectableRange = `${startTime} - ${endTime}`
    }
  },

  data() {
    let validateDate1 = (rule, value, callback) => {
      if (value && value !== '') {
        let startTime = this.returnTime1('startTime')
        let endTime = this.returnTime2('endTime')
        if (!checkTimeLimit(startTime, endTime)) {
          callback(new Error('开始时间必须小于结束时间'))
        } else if (!this.timeLimit(startTime, endTime)) {
          callback(new Error(`时间范围在${this.timeDiff}分钟内`))
        } else {
        }
        callback()
      } else {
        callback()
      }
    }
    let validateDate2 = (rule, value, callback) => {
      if (value && value !== '') {
        let startTime = this.returnTime1('startTime')
        let endTime = this.returnTime2('endTime')
        if (!checkTimeLimit(startTime, endTime)) {
          callback(new Error('开始时间必须小于结束时间'))
        } else if (!this.timeLimit(startTime, endTime)) {
          callback(new Error(`时间范围在${this.timeDiff}分钟内`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      timeDiff: 15,
      currentPageParams:null,//暂存本页数据，区分系统
      form: {
        startTime: null,
        endTime: null
      },
      startDay:'',
      endDay:'',
      pickerOptions: {
        selectableRange: '18:30:00 - 20:30:00'
      },
      endpickerOptions: {
        selectableRange: '18:30:00 - 20:30:00'
      },
      rules: {
        startTime: [
          {
            type: 'date',
            required: true,
            message: '请选择时间',
            trigger: 'change'
          },
          { validator: validateDate1, trigger: 'change' }
        ],
        endTime: [
          {
            type: 'date',
            required: true,
            message: '请选择时间',
            trigger: 'change'
          },
          { validator: validateDate2, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    timeLimit(start, end) {
      let timeDiff =
        (new Date(end).valueOf() - new Date(start).valueOf()) / 1000 / 60
      if (timeDiff > this.timeDiff) {
        return false
      } else {
        return true
      }
    },
    /**获取时间 */
    returnTime1(name, value) {
      return `${this.startDay} ${formatDate(this.form[name]).split(' ')[1]}`
    },
    returnTime2(name, value) {
      return `${this.endDay} ${formatDate(this.form[name]).split(' ')[1]}`
    },
    /** 点击查询 */
    serch() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let startTime = this.returnTime1('startTime')
          let endTime = this.returnTime2('endTime')
          let data = {
            startTime,
            endTime
          }
          this.$emit('submit', data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.reportForm {
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-button {
    span {
      color: #fff;
    }
  }
}
</style>
