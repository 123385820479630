var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "offlineReminder", staticClass: "offline-remind" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            stripe: "",
            border: "",
            height: _vm.tableHeight
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号码",
              width: 110 * _vm.$store.state.vehicle.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "公司",
              width: 160 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "longTimeOffline",
              label: "是否长时间离线",
              width: 160 * _vm.$store.state.vehicle.screenCoefficient
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("filterOffline")(scope.row.longTimeOffline))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gpsTime",
              label: "离线时间",
              width: 180 * _vm.$store.state.vehicle.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "location",
              label: "位置",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmType",
              label: "报警类型",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.typeObj[scope.row.alarmType]) + " ")
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "current-page": _vm.form.currentPage,
              "page-size": _vm.form.pageSize
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.form, "currentPage", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.form, "currentPage", $event)
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }