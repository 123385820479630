var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "tableAlarm", staticClass: "table-alarm" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "70",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号码",
              width: 120 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmLevelStr",
              label: "报警等级",
              width: 80 * _vm.screenCoefficient
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.levelObj[Number(scope.row.alarmLevel)]))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车组",
              width: 120 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driverName",
              label: "驾驶员",
              width: 80 * _vm.screenCoefficient,
              formatter: _vm.formatNull,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "driverCertNo",
              label: "从业资格证号",
              width: 120 * _vm.screenCoefficient,
              formatter: _vm.formatNull,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmSignTime",
              label: "报警时间",
              width: 180 * _vm.screenCoefficient,
              formatter: _vm.formatNull,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmName",
              label: "报警类型",
              width: 130 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.typeObj[Number(scope.row.alarmType)]))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "address",
              label: "位置",
              "show-overflow-tooltip": true,
              width: 320 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmContent",
              label: "报警附加信息",
              "show-overflow-tooltip": true,
              width: 130 * _vm.screenCoefficient
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }