<template>
  <div class="trail-form">
    <el-form
      :rules="rules"
      ref="form"
      :inline="true"
      label-width="82px"
      label-position="left"
      :model="form"
      class="trail-form"
    >
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" prop="vehId">
        <car-tree ref="carSelectTree" label="id" @getData="getVehIds" :choosedCph="choosedCph"></car-tree>
      </el-form-item>
      <!-- 开始时间： -->
      <el-form-item prop="beginTime" label="开始时间：">
        <el-date-picker
          class="marginR sm-time-label"
          size="small"
          v-model="form.beginTime"
          type="datetime"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="endTime" label="结束时间：">
        <el-date-picker
          class="sm-time-label"
          size="small"
          v-model="form.endTime"
          type="datetime"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="直接导出：">
        <el-switch v-model="radioValue"></el-switch>
      </el-form-item>
      <div></div>
      <el-form-item v-if="radioValue" label="  " label-width="10px">
        <el-button
          class="marginR smFont"
          type="primary"
          size="small"
          :loading="downLoadStatus"
          @click="directExport(true)"
        >历史轨迹直接导出</el-button>
      </el-form-item> -->
      <!-- <el-form-item v-else label="  " label-width="10px"> -->
      <el-form-item label="  " label-width="12px">
        <el-button
          class="marginR smFont"
          type="primary"
          size="small"
          :disabled="SearchFlag"
          @click="trailSearch"
        >查询</el-button>
        <el-button class="marginR20" size="small" @click="quitTrailReplay">取消</el-button>
      </el-form-item>
      <slot></slot>
    </el-form>
  </div>
</template>

<script>
import {
  getStartDate,
  formatDate,
  checkTimeLimit,
  checkTimeLimitDay,
} from '@/common/utils/index'
import carTree from '@/components/carTree/carSelectTree.vue'

export default {
  props: {
    //选择车辆回显-点击树-点击面板: 
    choosedCph: {
      type: String,
      default: ''
    },
    //轨迹回放查询防止多次点击flag
    flag: {
      type: Boolean,
      default: false
    }
  },
  components: {
    carTree
  },
  watch: {
    flag (val) {
      this.SearchFlag = val;
    },
    radioValue (val) {
      this.$refs.form.clearValidate();
      this.trailSearchTime = val ? 31 : 5;
    }
  },
  data () {
    //不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error("不能选择未来时间"));
      }
      else {
        callback();
      }
    };
    let validateDate1 = (rule, value, callback) => {
      if (
        !checkTimeLimit(value, this.form.endTime)
      ) {
        callback(new Error("开始时间必须小于结束时间"));
      } if (!checkTimeLimitDay(value, this.form.endTime, this.trailSearchTime)) {
        callback(new Error(`查询时间范围不超过${this.trailSearchTime}天`));
      } else {
        callback();
      }
    };
    let validateDate2 = (rule, value, callback) => {
      if (
        !checkTimeLimit(this.form.beginTime, value)
      ) {
        callback(new Error("开始时间必须小于结束时间"));
      }
      if (!checkTimeLimitDay(value, this.form.endTime, this.trailSearchTime)) {
        callback(new Error(`查询时间范围不超过${this.trailSearchTime}天`));
      } else {
        callback();
      }
    };

    return {
      SearchFlag: false,//查询防止多次点击
      trailSearchTime: 5, // 轨迹回放可查询天数
      form: {
        vehId: "",
        beginTime: new Date(getStartDate()),
        endTime: new Date(),
      },
      radioValue: false,
      downLoadStatus:false,//轨迹直接导出
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              let now = new Date()
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        beginTime: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "blur"
          },
          { validator: validateDateForward, trigger: "blur" },
          { validator: validateDate1, trigger: "blur" }
        ],
        endTime: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "blur"
          },
          { validator: validateDateForward, trigger: "blur" },
          { validator: validateDate2, trigger: "blur" }
        ],
        vehId: [
          {
            required: true,
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    /**选择车辆 */
    getVehIds (id) {
      this.form.vehId = id // 车辆id
    },
    /** 点击查询，传值到父组件 */
    trailSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          data.beginTime = formatDate(data.beginTime)
          data.endTime = formatDate(data.endTime)
          this.$emit('getFormVal', data, true)
          this.SearchFlag = true;//禁止点击查询按钮--数据查询完毕可点击
        } else {
          return false
        }
      })
    },
    /** 退出 */
    quitTrailReplay () {
      this.$emit('quitTrailReplay')
    },
    //历史轨迹直接导出
    directExport (bool) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let objParam = { ...this.form, resolveAddress: bool }
          objParam.beginTime = formatDate(objParam.beginTime)
          objParam.endTime = formatDate(objParam.endTime)
          let data = {
            baseUrl: "base",
            fileName: `轨迹信息-${getCurent()}`,
            queryParam: JSON.stringify(objParam),
            queryPath: "/monitor/positionExport",
          };
          addExcelExport(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.downLoadStatus = false;
              this.$store.dispatch("setRefreshStatus", true);
            })
            .catch(() => {
              this.downLoadStatus = false;
            });
        }
      })
    },
  },
  created () {

  }
}
</script>

