<template>
  <div class="table-alarm" ref="tableAlarm">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :height="tableHeight"
    >
      <el-table-column
        type="index"
        label="序号"
        width="70"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号码"
        :width="120 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="alarmLevelStr"
        label="报警等级"
        :width="80 * screenCoefficient"
      >
        <template slot-scope="scope">{{
          levelObj[Number(scope.row.alarmLevel)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车组"
        :width="120 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="driverName"
        label="驾驶员"
        :width="80 * screenCoefficient"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="driverCertNo"
        label="从业资格证号"
        :width="120 * screenCoefficient"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="alarmSignTime"
        label="报警时间"
        :width="180 * screenCoefficient"
        :formatter="formatNull"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="alarmName"
        label="报警类型"
        :width="130 * screenCoefficient"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.alarmType)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="位置"
        :show-overflow-tooltip="true"
        :width="320 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="alarmContent"
        label="报警附加信息"
        :show-overflow-tooltip="true"
        :width="130 * screenCoefficient"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { queryRealTimeAlarm } from '@/api/lib/refreshAlarm'
import { queryDictsByCodes } from '@/api/lib/api.js'
import { formatDict } from '@/common/utils/index'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tableHeight: 0,
      circleTime: 30 * 1000,
      tableData: [],
      timerAlarm: null,
      levelObj: null,
      typeObj: null
    }
  },
  props: {
    /** tab当前展示栏 */
    activeName: {
      type: String,
      default:''
    }
  },
  computed: {
    ...mapGetters({
      screenCoefficient: 'screenCoefficient'
    })
  },
  watch: {
    activeName(val) {
      if (val === 'alarm') {
        this.getAlarmListInterval()
      } else {
        clearInterval(this.timerAlarm)
      }
    }
  },

  methods: {
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.tableAlarm.clientHeight
      this.tableHeight = wholeHeight - 10
    },
    clickRow(row) {
      this.$router.push('/alarmDeal')
    },
    /** 数据为空默认显示- */
    formatNull(row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    /* 轮询执行查询未处理报警数据 30秒 */
    getAlarmListInterval() {
      let self = this
      this.getDicts()
      /**谢 删除掉轮询 */
      // let circleTime = self.circleTime // 轮询时间间隔(ms)

      // if (self.timerAlarm) {
      //   clearInterval(self.timerAlarm)
      // }

      // const timer = function () {
        queryRealTimeAlarm({ system: sessionStorage.getItem('system') }).then(
          (res) => {
            self.tableData = res.data
          }
        )
      // }
      // timer()
      // self.timerAlarm = setInterval(() => {
      //   timer()
      // }, circleTime)
      // self.$once('hook:beforeDestroy', () => {
      //   clearInterval(self.timerAlarm)
      // })
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'BJDJ,D0008,D0009,D0010' }).then(
        (res) => {
          if (res.code === 1000) {
            this.levelObj = formatDict(res.data.BJDJ)
            this.typeObj = formatDict(
              [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
            )
          }
        }
      )
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.getAlarmListInterval()
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.table-alarm {
  width: 100%;
  box-sizing: border-box;
}
.el-table {
  td,
  th {
    text-align: center !important;
    font-size: 12px !important;
  }
  th {
    color: #000;
  }
  td {
    color: rgba($color: #000000, $alpha: 0.5);
  }
}

/deep/.el-table thead th.is-leaf {
  line-height: 36px;
}
</style>
