var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "tableAlarm", staticClass: "table-alarm" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "70",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "车队" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "车辆类型",
              "show-overflow-tooltip": true,
              width: "130"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "vehicleColor", label: "车牌颜色" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmContent",
              label: "异常原因",
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }